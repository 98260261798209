import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { TransationService } from '../transation.service';
import { MerchantService } from '../../merchants/merchant.service';
import { TOKEN_EXPIRED, INVALID_TOKEN } from 'src/app/app.server';
import { AppAuthService } from 'src/app/auth/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';


@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.css']
})
export class TransactionListComponent implements OnInit {

  merchants = [];
  selectedMerchant = { _id: null, username: 'all', name: 'All' };
  deleteId = '';
  displayedColumns = ['index', 'ticket.title', 'merchant_id.name', 'quantity', 'amount', 'status_id.name', 'created_at'];
  paymentDisplayedColumns = ['index', 'merchant', 'amount'];
  dataSource = new MatTableDataSource();
  paymentDataSource = new MatTableDataSource();
  range: Range = { fromDate: new Date(), toDate: new Date() };
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  isLoading = true;
  totalTicket: number;
  totalAmount: number;

  constructor(private _transactionService: TransationService, private _merchantService: MerchantService, private authService: AppAuthService) { }
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paymentSort') paymentSort: MatSort;
  @ViewChild('dateRangePicker') dateRangePicker;

  ngOnInit() {
    // get merchant data 
    this._merchantService.getMerchants().subscribe(
      (data) => {
        this.merchants = data;
        this.merchants.unshift({ _id: null, username: 'all', name: 'All' });
        // set initial selection
      },
      (error) => {
        if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
          this.authService.needLogin();
        }
      }
    )

    const today = new Date();
    const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);

    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const resetRange = { fromDate: currMonthStart, toDate: currMonthEnd };
    this.dateRangePicker.resetDates(resetRange);

    this.setupPresets();
    this.options = {
      presets: this.presets,
      format: 'mediumDate',
      range: { fromDate: currMonthStart, toDate: currMonthEnd },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false,
        hasBackdrop: false
      },
      cancelLabel: "Cancel",
      excludeWeekends:false,
      fromMinMax: {fromDate:fromMin, toDate:fromMax},
      toMinMax: {fromDate:toMin, toDate:toMax}
    };

  }

  selection = new SelectionModel<any>(true, []);


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
      console.log(this.selection);
      
  }
  changeValue(event) {
    console.log(event);
    
    this.selectedMerchant = event;
    this.reload();

  }

  // handler function that receives the updated date range object
  updateRange(range: Range) {
    this.range = range;
    this.reload();




  }

  // helper function to create initial presets
  setupPresets() {

    const backDate = (numOfDays) => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    }

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7)
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      { presetLabel: "Yesterday", range: { fromDate: yesterday, toDate: today } },
      { presetLabel: "Last 7 Days", range: { fromDate: minus7, toDate: today } },
      { presetLabel: "Last 30 Days", range: { fromDate: minus30, toDate: today } },
      { presetLabel: "This Month", range: { fromDate: currMonthStart, toDate: currMonthEnd } },
      { presetLabel: "Last Month", range: { fromDate: lastMonthStart, toDate: lastMonthEnd } }
    ]
  }


  onPaymentClick(){
    const selectedRows = this.selection.selected;
    console.log(selectedRows);
    let dictMerchantTransaction = {};
    for (let i = 0 ; i< selectedRows.length ; i++){
      let merchant_id = selectedRows[i].merchant_id._id;
      let merchant_name = selectedRows[i].merchant_id.name;
      if (dictMerchantTransaction.hasOwnProperty(merchant_id)){
        let oldAmount = dictMerchantTransaction[merchant_id].amount;
        let newAmount = oldAmount + selectedRows[i].amount;
        dictMerchantTransaction[merchant_id].amount = newAmount;
      }else{
        dictMerchantTransaction[merchant_id] = {name: merchant_name, amount: 0};
        let oldAmount = dictMerchantTransaction[merchant_id].amount;
        let newAmount = oldAmount + selectedRows[i].amount;
        dictMerchantTransaction[merchant_id].amount = newAmount;
      }
    }
    const tmpArray = [];
    let index = 1;
    for(let name in dictMerchantTransaction){
      let item = dictMerchantTransaction[name]
      item.index = index++;
      tmpArray.push(item);
    }

    this.paymentDataSource.data = tmpArray;
    
    this.paymentDataSource.sortingDataAccessor = (item, property) => {
      
      
      // console.log(property);
      switch (property) {
        case 'index': {
          return item['index'];
        };
        case 'merchant': {
          return item['name'];
        };
        case 'amount': {
          return item['amount'];
        };
        default: {
          return item[property];
        }
      }
    };
    this.paymentDataSource.sort = this.paymentSort;
    

  }

  onPay(){
    this._transactionService.pay(this.selection.selected).subscribe(

    )
    
  }
  reload(){
    this.dataSource.data = [];
    this.totalAmount = 0;
    this.totalTicket = 0;
    this.isLoading = true;
    this._transactionService.getTrantransactions(this.selectedMerchant['_id'], this.range.fromDate.getTime(), this.range.toDate.getTime()).subscribe(
      (data) => {
        this.isLoading = false;
        this.dataSource.data = data;
        data.forEach(element => {
          this.totalTicket = this.totalTicket + element['quantity'];
          this.totalAmount = this.totalAmount + element['amount'];
        });
      },
      (error) => {
        this.isLoading = false;
        if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
          this.authService.needLogin();
        }
      }
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      // console.log(property);
      switch (property) {
        case 'ticket.title': {
          return item['ticket'].title;
        };
        case 'merchant_id.name': {
          return item['merchant_id'].name;
        };
        case 'status_id.name': {
          return item['status_id'].name;
        };
        default: {
          return item[property];
        }
      }
    };
    this.dataSource.sort = this.sort;
  }
}