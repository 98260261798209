import { Component, ViewChild } from "@angular/core";
import { NgForm } from "../../../../node_modules/@angular/forms";
import { AppCategoriesService } from "../categories.service";
import { Router } from "../../../../node_modules/@angular/router";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../../app.server";
import { AppAuthService } from "../../auth/auth.service";

@Component({
  selector: 'app-categories-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class AppCategoriesNewComponent {
  @ViewChild('fCategory') categoryData: NgForm;
  title = '';
  description = '';
  previewIcon: any;
  fileIconBase64: any;

  constructor( private categorySevice: AppCategoriesService, private router: Router, private authService: AppAuthService) {}

  onSubmit() {
    this.categorySevice.addCategory( this.categoryData.value.title, this.categoryData.value.description, this.fileIconBase64)
    .subscribe(() => {
      this.router.navigateByUrl('/categories');
    },
  (error) => {
    console.log(error);
    if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
      this.authService.needLogin();
    }
  });
  }
  onIconFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.fileIconBase64 = reader.result;
      this.previewIcon = reader.result;
    };
    reader.readAsDataURL(file);
  }
  onGoBack() {
    this.router.navigateByUrl('/categories');
  }
}
