import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root'})
export class CategoryGuardService implements CanActivate {
  constructor(private router: Router, private route: ActivatedRoute) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | Observable<boolean> | boolean {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    if (token === null || role === null) {
      this.router.navigateByUrl('/login');
    }
    if ( role == 'System Admin') {
      return true;
    } else {
      alert('Permission not allow. Please contact administrator');
      return false;
    }
  }
}
