import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Log } from './log';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  BASE_END_POINT = environment.url;
  constructor(private _http: HttpClient) { }

  getLogs(merchant_id: string = null, start_date: string = undefined, end_date: string = undefined, page: number, count: number): Observable<Log[]> {

    let query = '';
    let pagination = "&page=" + page + "&count=" + count;
    if (merchant_id === '0') {
      query = 'start_date=' + start_date + '&end_date=' + end_date + '&token=' + localStorage.getItem('token');
    }
    else {
      query = 'merchant_id=' + merchant_id + '&start_date=' + start_date + '&end_date=' + end_date + '&token=' + localStorage.getItem('token');
    }
    return this._http.get<Log[]>(this.BASE_END_POINT + 'logs?' + query + pagination);
  }
}
