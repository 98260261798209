import { Component, ViewChild, OnInit } from "@angular/core";
import { NgForm } from "../../../../node_modules/@angular/forms";
import { AppCategoriesService } from "../categories.service";
import {
  Router,
  ActivatedRoute
} from "../../../../node_modules/@angular/router";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../../app.server";
import { AppAuthService } from "../../auth/auth.service";

@Component({
  selector: "app-categories-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.css"]
})
export class AppCategoriesEditComponent implements OnInit {
  @ViewChild("fCategory") categoryData: NgForm;
  title = "";
  description = "";
  previewIcon = "";
  fileIconBase64 = "";
  isLoading = false;
  private categoryId = null;

  constructor(
    private categorySevice: AppCategoriesService,
    private router: Router,
    private authService: AppAuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.categoryId = this.route.snapshot.params["id"];
    this.categorySevice.editCategory(this.categoryId).subscribe(
      category => {
        this.categoryId = category["_id"];
        this.title = category["title"];
        this.description = category["description"];
        this.previewIcon = category["icon"];
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        } else if (error.type === "error") {
          this.router.navigateByUrl("/categories");
        }
      }
    );
  }

  onSubmit() {
    this.isLoading = true;
    let prepareData = {};
    if (this.fileIconBase64 != "") {
      prepareData = {
        title: this.categoryData.value.title,
        description: this.categoryData.value.description,
        icon: this.fileIconBase64
      };
    } else {
      prepareData = {
        title: this.categoryData.value.title,
        description: this.categoryData.value.description
      };
    }
    this.categorySevice.updateCategory(this.categoryId, prepareData).subscribe(
      () => {
        this.isLoading = false;
        this.router.navigateByUrl("/categories");
      },
      error => {
        this.isLoading = false;
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }
  onIconFileChange(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.fileIconBase64 = reader.result as string;
      this.previewIcon = reader.result as string;
    };
    reader.readAsDataURL(file);
  }
  onGoBack() {
    this.router.navigateByUrl("/categories");
  }
}
