import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccess(message: any) {
    this.snackBar.open(message, null, {
      duration: 2000,
      panelClass: ["green-snackbar"],
      horizontalPosition: "right"
    });
  }

  showError(message: any) {
    this.snackBar.open(message, null, {
      duration: 2000,
      panelClass: ["red-snackbar"],
      horizontalPosition: "right"
    });
  }
}
