import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DestinationService } from "../destination.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ServiceTypeService } from "src/app/service-type/service-type.service";

@Component({
  selector: "app-edit-destination",
  templateUrl: "./edit-destination.component.html",
  styleUrls: ["./edit-destination.component.css"]
})
export class EditDestinationComponent implements OnInit {
  serviceTypes: any;
  selectedServiceType: any;
  constructor(
    public dialogRef: MatDialogRef<EditDestinationComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private destinationService: DestinationService,
    private serviceTypeService: ServiceTypeService
  ) {}
  destinationFormGroup: FormGroup;
  nameFormControl = new FormControl(this.object.name, [Validators.required]);
  descriptionControl = new FormControl(this.object.description, [
    Validators.required
  ]);
  serviceTypeControl = new FormControl("", [Validators.required]);

  ngOnInit() {
    this.destinationFormGroup = new FormGroup({
      nameControl: this.nameFormControl,
      descriptionControl: this.descriptionControl,
      serviceTypeControl: this.serviceTypeControl
    });
    this.getServiceTypes();
  }

  updateDestination() {
    const id = this.object._id;
    const data = {
      name: this.destinationFormGroup.get("nameControl").value,
      description: this.destinationFormGroup.get("descriptionControl").value,
      service_type: this.selectedServiceType._id
    };
    this.destinationService.updateDestination(id, data).subscribe(
      res => {
        this.dialogRef.close("ok");
      },
      error => {
        console.log(error);
        this.dialogRef.close("cancel");
      }
    );
  }

  cancel() {
    this.dialogRef.close("cancel");
  }

  getServiceTypes() {
    this.serviceTypeService.getServiceTypes().subscribe(
      (res: any) => {
        this.serviceTypes = res;
        this.selectedServiceType = this.serviceTypes[0];
        this.serviceTypes.forEach(element => {
          if (element._id === this.object.service_type._id) {
            this.selectedServiceType = element;
          }
        });
        if (this.serviceTypes.length < 1) {
          this.selectedServiceType = null;
        }
      },
      error => {
        console.log(error);
      }
    );
    this.destinationFormGroup.updateValueAndValidity();
  }
}
