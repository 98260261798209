import { Injectable } from '@angular/core';
import { TOKEN_EXPIRED, INVALID_TOKEN } from '../app.server';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject, Observable } from '../../../node_modules/rxjs';
import { Router } from '@angular/router';
import { Currency } from './currency';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AppCurrencyService {
  private currencies: any[];
  BASE_END_POINT = environment.url;
  private currencyUpdated = new Subject<any[]>();

  constructor(private http: HttpClient, private router: Router) { }

  // getCurrencies(): void {
  //   let series = 0;
  //   this.http.get(BASE_END_POINT + 'currencies?token=' + localStorage.getItem('token'))
  //     .pipe(
  //       map((ticketsData: any[]) => {
  //         return ticketsData.map((currency: any[]) => {
  //           series++;
  //           return {
  //             ...currency,
  //             series: series
  //           };
  //         });
  //       })
  //     )
  //     .subscribe(transformCategories => {
  //       this.currencies = transformCategories;
  //       this.currencyUpdated.next([...this.currencies]);
  //     },
  //   (error) => {
  //     console.log(error);
  //     if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
  //       localStorage.clear();
  //       this.router.navigateByUrl('/login');
  //     }
  //   }
  //   );
  // }

  getCurrencies(): Observable<Currency[]> {
    return this.http.get<Currency[]>(this.BASE_END_POINT + 'exchange_rates?token=' + localStorage.getItem('token'));
  }

  getExchangeRatePagination(page: number, count: number): Observable<Currency[]> {
    let query = "&page=" + page + "&count=" + count;
    return this.http.get<Currency[]>(this.BASE_END_POINT + 'exchange_rates?token=' + localStorage.getItem('token') + query);
  }

  currencyUpdateListener() {
    return this.currencyUpdated.asObservable();
  }
  addCurrency(formData: FormData) {
    return this.http.post(this.BASE_END_POINT + 'exchange_rates?token=' + localStorage.getItem('token'), formData);
  }
  editCurrency(id: string) {
    return this.http.get(this.BASE_END_POINT + 'exchange_rates/' + id + '?token=' + localStorage.getItem('token'));
  }
  updateCurrency(id: string, formData: FormData) {
    return this.http.patch(this.BASE_END_POINT + 'exchange_rates/' + id + '?token=' + localStorage.getItem('token'), formData);
  }
  deleteCurrency(id: string) {
    return this.http.delete(this.BASE_END_POINT + 'exchange_rates/' + id + '?token=' + localStorage.getItem('token'));
  }
}
