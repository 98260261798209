import { Component, OnInit } from "@angular/core";
import { Chart } from "chart.js";
import { DashboardService } from "./dashboard.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
  dailyChart = [];
  dailyData = [];
  dailyLabel = [];
  dailyChartAmount = [];
  dailyLabelAmount = [];
  dailyDataAmount = [];
  dailyLabelsServiceIncome = [];
  dailyServiceIncomeData = [];
  dailyServiceIncomeChart = [];
  monthlyChart = [];
  monthLabels = [];
  monthDataArray = [];
  monthlyChartAmount = [];
  monthLabelsAmount = [];
  monthDataArrayAmount = [];
  monthLabelsServiceIncome = [];
  monthlyServiceIncomeChart = [];
  monthlyServiceIncomeData = [];

  ticketsSoldToday: any = 0;
  totalAmountToday: any = 0;
  totalOrderToday: any = 0;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
    this.getTodayStats();
    this.getMonthlyStats();
    this.getDailyStat();
    this.getMonthlyServiceIncomeStatistic();
    this.getDailyServiceIncomeStatistic();
  }

  getDailyServiceIncomeStatistic() {
    this.dashboardService.getDailyServiceIncome().subscribe((res: any) => {
      res.forEach(value => {
        this.dailyLabelsServiceIncome.push(
          value.date.day + '-' + value.date.month + '-' + value.date.year
        )
        this.dailyServiceIncomeData.push(value.income);
      })
      //generate graph
      this.generateDailyServiceIncomeChart();
    })
  }

  getMonthlyServiceIncomeStatistic() {
    this.dashboardService.getMonthlyServiceIncome().subscribe((res: any) => {
      res.forEach((value) => {
        this.monthLabelsServiceIncome.push(
          this.formatDateToMonth(value.date.month, value.date.year)
        )
        this.monthlyServiceIncomeData.push(value.income)
      });
      //generate graph
      this.generateMonthlyServiceIncomeChart();
    })
  }

  getTodayStats() {
    this.dashboardService.getTodayStatistics().subscribe(
      (res: any) => {
        if (res.length > 0) {
          this.totalAmountToday = res[0].total_price;
          this.ticketsSoldToday = res[0].total_quantity;
          this.totalOrderToday = res[0].total_order;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getMonthlyStats() {
    this.dashboardService.getMonthlyStatistics().subscribe(
      (res: any) => {
        // console.log("monthly ", res);
        for (let i = 0; i < res.length; i++) {
          this.monthLabels.push(
            this.formatDateToMonth(res[i].updatedAt.month, res[i].updatedAt.year)
          );
          this.monthDataArray.push(res[i].total_quantity);
          this.monthLabelsAmount.push(
            this.formatDateToMonth(res[i].updatedAt.month, res[i].updatedAt.year)

          );
          // console.log("each month; ", res[i].updatedAt.month)
          // console.log("month: ", this.monthLabelsAmount)
          this.monthDataArrayAmount.push(res[i].total_price);
        }
        this.generateMonthlyChart();
        this.generateMonthlyChartAmount();
      },
      error => {
        console.log(error);
      }
    );
  }

  formatDateToMonth(month: any, year: any) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    // const newDate = new Date(year, month);
    return monthNames[month - 1] + "-" + year;
  }

  generateMonthlyChart() {
    this.monthlyChart = new Chart("monthly_chart", {
      type: "bar",
      data: {
        labels: this.monthLabels,
        datasets: [
          {
            label: "Number of Tickets Sold",
            data: this.monthDataArray,
            backgroundColor: "#00C0EF",
            borderColor: "#00C0EF",
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    });
  }

  generateMonthlyChartAmount() {
    this.monthlyChart = new Chart("monthly_chart_amount", {
      type: "bar",
      data: {
        labels: this.monthLabelsAmount,
        datasets: [
          {
            label: "Total Amount earned",
            data: this.monthDataArrayAmount,
            backgroundColor: "#00C0EF",
            borderColor: "#00C0EF",
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    });
  }

  generateMonthlyServiceIncomeChart() {
    this.monthlyServiceIncomeChart = new Chart("monthly_service_income", {
      type: "bar",
      data: {
        labels: this.monthLabelsServiceIncome,
        datasets: [
          {
            label: "Total Service Income",
            data: this.monthlyServiceIncomeData,
            backgroundColor: "#00C0EF",
            borderColor: "#00C0EF",
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    })
  }

  generateDailyServiceIncomeChart() {
    this.dailyServiceIncomeChart = new Chart("daily_service_income", {
      type: "bar",
      data: {
        labels: this.dailyLabelsServiceIncome,
        datasets: [
          {
            label: "Total Service Income",
            data: this.dailyServiceIncomeData,
            backgroundColor: "#00C0EF",
            borderColor: "#00C0EF",
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    })
  }

  getDailyStat() {
    this.dashboardService.getDailyStatistics().subscribe(
      (res: any) => {
        // console.log("daily", res);

        for (let i = res.length - 1; i >= 0; i--) {
          const date =
            "" +
            res[i].updatedAt.day +
            "-" +
            res[i].updatedAt.month +
            "-" +
            res[i].updatedAt.year;
          this.dailyData.push(res[i].total_quantity);
          this.dailyLabel.push(date);
          this.dailyDataAmount.push(res[i].total_price);
          this.dailyLabelAmount.push(date);
        }
        this.generateDailyStat();
        this.generateDailyStatsAmount();
      },
      error => {
        console.log(error);
      }
    );
  }

  generateDailyStat() {
    this.dailyChart = new Chart("daily_chart", {
      type: "bar",
      data: {
        labels: this.dailyLabel,
        datasets: [
          {
            label: "Number of Tickets Sold",
            data: this.dailyData,
            backgroundColor: "#00C0EF",
            borderColor: "#00C0EF",
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 5
              }
            }
          ]
        }
      }
    });
  }

  // getDailyStatAmount(weekCount: any) {
  //   this.dashboardService.getWeeklyStatistics(weekCount).subscribe(
  //     (res: any) => {
  //       for (let i = 0; i < res.length; i++) {
  //         this.weeklyLabel.push("Week " + res[i].week);
  //         this.weeklyData.push(res[i].total_ticket_sold);
  //       }
  //       this.generateWeeklyStats();
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }

  generateDailyStatsAmount() {
    this.dailyChartAmount = new Chart("daily_chart_amount", {
      type: "bar",
      data: {
        labels: this.dailyLabelAmount,
        datasets: [
          {
            label: "Total amount earned",
            data: this.dailyDataAmount,
            backgroundColor: "#00C0EF",
            borderColor: "#00C0EF",
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    });
  }
}
