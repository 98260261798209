import { Component } from "@angular/core";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class AppOrdersComponent {

}
