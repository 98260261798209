import { Component, OnInit, ViewChild } from "@angular/core";
import { SaleShiftService } from "../sale-shift.service";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { AppAuthService } from "src/app/auth/auth.service";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";

@Component({
  selector: "app-sale-shift-list",
  templateUrl: "./sale-shift-list.component.html",
  styleUrls: ["./sale-shift-list.component.css"]
})
export class SaleShiftListComponent implements OnInit {
  constructor(private saleShiftService: SaleShiftService, private authService: AppAuthService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    "index",
    "pos",
    "open_amount_riel",
    "open_amount_dollar",
    "open_date",
    "close_amount_riel",
    "close_amount_dollar",
    "close_date",
    "is_close",
    "is_clear",
    "action"
  ];
  dataSource = new MatTableDataSource();
  isLoading = true;
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];

  ngOnInit() {
    this.reload(this.currentPage);
  }

  getAllSaleShifts() {
    this.dataSource.data = [];
    this.isLoading = true;
    this.saleShiftService.getSaleShifts(this.currentPage, this.count).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.dataSource.data = result;
        console.log("sale_shift: ", result);
      },
      error => {
        this.isLoading = false;
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  clear(id: any) {
    this.saleShiftService.clear(id).subscribe(
      (res) => {
        this.getAllSaleShifts();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  unclear(id: any) {
    this.saleShiftService.unclear(id).subscribe(
      (res) => {
        this.getAllSaleShifts();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  reload(page: any) {
    if (page) {
      this.currentPage = page;
    }
    this.getAllSaleShifts();
  }

  onTypeChange() {
    this.currentPage = 1;
    this.reload(this.currentPage);
  }
  /** Function for pagination */
  onOptionCountChange() {
    // console.log("event");
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
