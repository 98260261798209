import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { DataTablesModule } from "angular-datatables";
import { SatDatepickerModule, SatNativeDateModule } from "saturn-datepicker";
import {
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatCardModule,
  MatSnackBarModule,
  MatDialogModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatButtonToggleModule,
  MatSelectModule,
  MatOptionModule,
  MatDatepickerModule
} from "@angular/material";
// import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgSelectModule } from "@ng-select/ng-select";

import { NgxMatDrpModule } from "ngx-mat-daterange-picker";

import { AppComponent } from "./app.component";
import { AppHomeComponent } from "./home/home.component";
import { AppLoginComponent } from "./auth/auth.component";
import { RouterModule, Routes } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProviderGuardService } from "./security/ProviderGuard.service";
import { CategoryGuardService } from "./security/CategoryGuard.service";
import { LoginGuardService } from "./security/LoginGuard.service";
import { HomeGuardService } from "./security/HomeGuard.service";
import { AppCategoriesComponent } from "./categories/categories.component";
import { AppCategoriesNewComponent } from "./categories/new/new.component";
import { AppCategoriesListComponent } from "./categories/list/list.component";
import { AppCategoriesEditComponent } from "./categories/edit/edit.component";
import { AppServicesComponent } from "./services/services.component";
import { AppTicketsListComponent } from "./services/list/list.component";
import { AppOrdersComponent } from "./orders/orders.component";
import { AppOrderListComponent } from "./orders/list/list.component";
import { AppCurrenciesComponent } from "./currencies/currencies.component";
import { AppCurrencyListComponent } from "./currencies/list/list.component";
import { AppCurrencyNewComponent } from "./currencies/new/new.component";
import { AppCurrencyEditComponent } from "./currencies/edit/edit.component";
import { AppTicketNewComponent } from "./services/new/new.component";
import { AppTicketEditComponent } from "./services/edit/edit.component";
import { formatDate } from "src/app/orders/formatDate.pipe";
import { AppOrderEditComponent } from "./orders/edit/edit.component";
import { MerchantsComponent } from "./merchants/merchants.component";
import { MerchantListComponent } from "./merchants/merchant-list/merchant-list.component";
import { MerchantNewComponent } from "./merchants/merchant-new/merchant-new.component";
import { PosComponent } from "./pos/pos.component";
import { PosListComponent } from "./pos/pos-list/pos-list.component";
import { PosNewComponent } from "./pos/pos-new/pos-new.component";
import { TransactionsComponent } from "./transactions/transactions.component";
import { TransactionListComponent } from "./transactions/transaction-list/transaction-list.component";
import { MerchantEditComponent } from "./merchants/merchant-edit/merchant-edit.component";
import { UsersComponent } from "./users/users.component";
import { UserListComponent } from "./users/user-list/user-list.component";
import { UserNewComponent } from "./users/user-new/user-new.component";
import { UserEditComponent } from "./users/user-edit/user-edit.component";
import { PosEditComponent } from "./pos/pos-edit/pos-edit.component";
import { MerchantPaymentComponent } from "./merchant-payment/merchant-payment.component";
import { MerchantPaymentListComponent } from "./merchant-payment/merchant-payment-list/merchant-payment-list.component";
import { MerchantPaymentHistoryComponent } from "./merchant-payment-history/merchant-payment-history.component";
import { HistoryListComponent } from "./merchant-payment-history/history-list/history-list.component";
import { SubMerchantComponent } from "./sub-merchant/sub-merchant.component";
import { SubMerchantListComponent } from "./sub-merchant/sub-merchant-list/sub-merchant-list.component";
import { SubMerchantNewComponent } from "./sub-merchant/sub-merchant-new/sub-merchant-new.component";
import { SubMerchantEditComponent } from "./sub-merchant/sub-merchant-edit/sub-merchant-edit.component";

import { SortablejsModule } from "angular-sortablejs";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { MerchantServiceComponent } from "./merchant-service/merchant-service.component";
import { MerchantServiceListComponent } from "./merchant-service/merchant-service-list/merchant-service-list.component";
import { CreateMerchantServiceDialogComponent } from "./merchant-service/create-merchant-service-dialog/create-merchant-service-dialog.component";
import { EditMerchantServiceDialogComponent } from "./merchant-service/edit-merchant-service-dialog/edit-merchant-service-dialog.component";
import { ServiceTypeComponent } from "./service-type/service-type.component";
import { SaleShiftComponent } from "./sale-shift/sale-shift.component";
import { SaleShiftListComponent } from "./sale-shift/sale-shift-list/sale-shift-list.component";
import { ServiceTypeListComponent } from "./service-type/service-type-list/service-type-list.component";
import { CreateServiceTypeDialogComponent } from "./service-type/create-service-type-dialog/create-service-type-dialog.component";
import { EditServiceTypeDialogComponent } from "./service-type/edit-service-type-dialog/edit-service-type-dialog.component";
import { ServiceDetailComponent } from "./service-detail/service-detail.component";
import { ServiceDetailListComponent } from "./service-detail/service-detail-list/service-detail-list.component";
import { CreateServiceDetailComponent } from "./service-detail/create-service-detail/create-service-detail.component";
import { EditServiceDetailComponent } from "./service-detail/edit-service-detail/edit-service-detail.component";
import { YesNoPipe } from "src/pipes/yes-no.pipe";
import { IsAdminGuard } from "./guard/is-admin.guard";
import { DestinationComponent } from "./destination/destination.component";
import { DestinationListComponent } from "./destination/destination-list/destination-list.component";
import { CreateDestinationComponent } from "./destination/create-destination/create-destination.component";
import { EditDestinationComponent } from "./destination/edit-destination/edit-destination.component";
import { LanguageComponent } from "./language/language.component";
import { LanguageListComponent } from "./language/language-list/language-list.component";
import { CreateLanguageComponent } from "./language/create-language/create-language.component";
import { EditLanguageComponent } from "./language/edit-language/edit-language.component";
import { MerchantFilterByTypePipe } from "src/pipes/merchant-filter-by-type.pipe";
import { DestinationServiceComponent } from "./destination-service/destination-service.component";
import { DestinationServiceListComponent } from "./destination-service/destination-service-list/destination-service-list.component";
import { CreateDestinationServiceDialogComponent } from "./destination-service/create-destination-service-dialog/create-destination-service-dialog.component";
import { EditDestinationServiceDialogComponent } from "./destination-service/edit-destination-service-dialog/edit-destination-service-dialog.component";
import { OptionComponent } from "./option/option.component";
import { OptionListComponent } from "./option/option-list/option-list.component";
import { CreateOptionDialogComponent } from "./option/create-option-dialog/create-option-dialog.component";
import { EditOptionDialogComponent } from "./option/edit-option-dialog/edit-option-dialog.component";
import { MerchantOptionComponent } from './merchant-option/merchant-option.component';
import { MerchantOptionListComponent } from './merchant-option/merchant-option-list/merchant-option-list.component';
import { EditMerchantOptionComponent } from './merchant-option/edit-merchant-option/edit-merchant-option.component';
import { CreateMerchantOptionComponent } from './merchant-option/create-merchant-option/create-merchant-option.component';

const appRoutes: Routes = [
  {
    path: "login",
    component: AppLoginComponent,
    pathMatch: "full",
    canActivate: [LoginGuardService]
  },
  {
    path: "",
    component: AppHomeComponent,
    canActivate: [HomeGuardService],
    children: [
      {
        path: "users",
        component: UsersComponent,
        children: [
          { path: "", component: UserListComponent },
          { path: "new", component: UserNewComponent },
          { path: ":id/edit", component: UserEditComponent }
        ]
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [IsAdminGuard]
      },
      {
        path: "merchants",
        component: MerchantsComponent,
        children: [
          { path: "", component: MerchantListComponent },
          { path: "new", component: MerchantNewComponent },
          { path: ":id/edit", component: MerchantEditComponent }
        ],
        canActivate: [IsAdminGuard]
      },
      {
        path: "sub-merchants",
        component: SubMerchantComponent,
        children: [
          { path: "", component: SubMerchantListComponent },
          { path: "new", component: SubMerchantNewComponent },
          { path: ":id/edit", component: SubMerchantEditComponent }
        ],
        canActivate: [IsAdminGuard]
      },
      {
        path: "pos",
        component: PosComponent,
        children: [
          { path: "", component: PosListComponent },
          { path: "new", component: PosNewComponent },
          { path: ":id/edit", component: PosEditComponent }
        ],
        canActivate: [IsAdminGuard]
      },
      {
        path: "categories",
        component: AppCategoriesComponent,
        canActivate: [CategoryGuardService, IsAdminGuard],
        children: [
          { path: "", component: AppCategoriesListComponent },
          { path: "new", component: AppCategoriesNewComponent },
          { path: ":id/edit", component: AppCategoriesEditComponent }
        ]
      },
      {
        path: "orders",
        component: AppOrdersComponent,
        children: [
          { path: "", component: AppOrderListComponent },
          { path: ":id/edit", component: AppOrderEditComponent }
        ],
        canActivate: [IsAdminGuard]
      },
      {
        path: "merchant_payment",
        component: MerchantPaymentComponent,
        children: [{ path: "", component: MerchantPaymentListComponent }],
        canActivate: [IsAdminGuard]
      },
      {
        path: "merchant_payment_history",
        component: MerchantPaymentHistoryComponent,
        children: [{ path: "", component: HistoryListComponent }],
        canActivate: [IsAdminGuard]
      },
      {
        path: "services",
        component: AppServicesComponent,
        children: [
          { path: "", component: AppTicketsListComponent },
          { path: "new", component: AppTicketNewComponent },
          { path: ":id/edit", component: AppTicketEditComponent }
        ],
        canActivate: [IsAdminGuard]
      },
      {
        path: "currencies",
        component: AppCurrenciesComponent,
        children: [
          { path: "", component: AppCurrencyListComponent },
          { path: "new", component: AppCurrencyNewComponent },
          { path: ":id/edit", component: AppCurrencyEditComponent }
        ],
        canActivate: [IsAdminGuard]
      },
      {
        path: "merchant_services",
        component: MerchantServiceComponent,
        children: [{ path: "", component: MerchantServiceListComponent }],
        canActivate: [IsAdminGuard]
      },
      {
        path: "sale_shifts",
        component: SaleShiftComponent,
        children: [{ path: "", component: SaleShiftListComponent }]
      },
      {
        path: "service_types",
        component: ServiceTypeComponent,
        children: [{ path: "", component: ServiceTypeListComponent }],
        canActivate: [IsAdminGuard]
      },
      {
        path: "service_details",
        component: ServiceDetailComponent,
        children: [{ path: "", component: ServiceDetailListComponent }],
        canActivate: [IsAdminGuard]
      },
      {
        path: "destinations",
        component: DestinationComponent,
        children: [{ path: "", component: DestinationListComponent }]
      },
      {
        path: "languages",
        component: LanguageComponent,
        children: [{ path: "", component: LanguageListComponent }],
        canActivate: [IsAdminGuard]
      },
      {
        path: "destination_services",
        component: DestinationServiceComponent,
        children: [{ path: "", component: DestinationServiceListComponent }],
        canActivate: [IsAdminGuard]
      },
      {
        path: "options",
        component: OptionComponent,
        children: [{ path: "", component: OptionListComponent }],
        canActivate: [IsAdminGuard]
      },
      {
        path: "merchant_options",
        component: MerchantOptionComponent,
        children: [{ path: "", component: MerchantOptionListComponent }],
        canActivate: [IsAdminGuard]
      }
    ]
  },
  { path: "**", redirectTo: "dashboard" }
];

@NgModule({
  declarations: [
    AppComponent,
    AppLoginComponent,
    AppHomeComponent,
    AppCategoriesComponent,
    AppCategoriesListComponent,
    AppCategoriesNewComponent,
    AppCategoriesEditComponent,
    AppServicesComponent,
    AppTicketsListComponent,
    AppTicketNewComponent,
    AppTicketEditComponent,
    AppOrdersComponent,
    AppOrderListComponent,
    AppOrderEditComponent,
    formatDate,
    AppCurrenciesComponent,
    AppCurrencyListComponent,
    AppCurrencyNewComponent,
    AppCurrencyEditComponent,
    MerchantsComponent,
    MerchantListComponent,
    MerchantNewComponent,
    PosComponent,
    PosListComponent,
    PosNewComponent,
    TransactionsComponent,
    TransactionListComponent,
    MerchantEditComponent,
    UsersComponent,
    UserListComponent,
    UserNewComponent,
    UserEditComponent,
    PosEditComponent,
    MerchantPaymentComponent,
    MerchantPaymentListComponent,
    MerchantPaymentHistoryComponent,
    HistoryListComponent,
    SubMerchantComponent,
    SubMerchantListComponent,
    SubMerchantNewComponent,
    SubMerchantEditComponent,
    DashboardComponent,
    MerchantServiceComponent,
    MerchantServiceListComponent,
    CreateMerchantServiceDialogComponent,
    EditMerchantServiceDialogComponent,
    ServiceTypeComponent,
    SaleShiftComponent,
    SaleShiftListComponent,
    ServiceTypeListComponent,
    CreateServiceTypeDialogComponent,
    EditServiceTypeDialogComponent,
    ServiceDetailComponent,
    ServiceDetailListComponent,
    CreateServiceDetailComponent,
    EditServiceDetailComponent,
    YesNoPipe,
    DestinationComponent,
    DestinationListComponent,
    CreateDestinationComponent,
    EditDestinationComponent,
    LanguageComponent,
    LanguageListComponent,
    CreateLanguageComponent,
    EditLanguageComponent,
    MerchantFilterByTypePipe,
    DestinationServiceComponent,
    DestinationServiceListComponent,
    CreateDestinationServiceDialogComponent,
    EditDestinationServiceDialogComponent,
    OptionComponent,
    OptionListComponent,
    CreateOptionDialogComponent,
    EditOptionDialogComponent,
    MerchantOptionComponent,
    MerchantOptionListComponent,
    EditMerchantOptionComponent,
    CreateMerchantOptionComponent
  ],
  imports: [
    BrowserModule,
    DataTablesModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
    SortablejsModule.forRoot({ animation: 150 }),
    MatTableModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    MatSortModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    MatRippleModule,
    MatSelectModule,
    MatOptionModule,
    NgSelectModule,
    NgxMatDrpModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    CreateMerchantServiceDialogComponent,
    EditMerchantServiceDialogComponent,
    CreateServiceTypeDialogComponent,
    EditServiceTypeDialogComponent,
    CreateServiceDetailComponent,
    EditServiceDetailComponent,
    CreateDestinationComponent,
    EditDestinationComponent,
    CreateLanguageComponent,
    EditLanguageComponent,
    CreateDestinationServiceDialogComponent,
    EditDestinationServiceDialogComponent,
    CreateOptionDialogComponent,
    EditOptionDialogComponent,
    CreateMerchantOptionComponent,
    EditMerchantOptionComponent
  ]
})
export class AppModule { }
