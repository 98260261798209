import { Component, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";
import { AppAuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.css"]
})
export class UserEditComponent implements OnInit {
  @ViewChild("userForm") userForm: NgForm;

  roles = [];
  defaultRole = "5b90a37755cee9325fcebd25";
  userId = "";
  id = "";
  role = "";
  name = "";
  username = "";
  password = "";

  constructor(
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _router: Router,
    private authService: AppAuthService
  ) {}

  ngOnInit() {
    this._userService.getRoles().subscribe(
      (roles: any[]) => {
        roles.forEach((value, index) => {
          if (value["name"] === "Merchant") {
            roles.splice(index, 1);
          }
        });
        roles.forEach((value, index) => {
          if (value["name"] === "POS") {
            roles.splice(index, 1);
          }
        });
        this.roles = roles;
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
    this.userId = this._route.snapshot.params["id"];
    this._userService.editUser(this.userId).subscribe(
      (user: any) => {
        this.id = user._id;
        this.role = user.role._id;
        this.name = user.name;
        this.username = user.username;
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  onSubmit() {
    const user = {
      name: this.userForm.value.name,
      username: this.userForm.value.username,
      password:
        this.userForm.value.password == ""
          ? undefined
          : this.userForm.value.password,
      role: this.userForm.value.role
    };
    this._userService.updateUser(this.id, user).subscribe(
      () => {
        this._router.navigateByUrl("/users");
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  onGoBack() {
    this._router.navigate(["../../"], { relativeTo: this._route });
  }
}
