import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.css']
})
export class DestinationComponent implements OnInit {

  
  constructor() { }

  ngOnInit() {
  }

}
