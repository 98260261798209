import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ServiceDetailServiceService } from "../service-detail-service.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-edit-service-detail",
  templateUrl: "./edit-service-detail.component.html",
  styleUrls: ["./edit-service-detail.component.css"]
})
export class EditServiceDetailComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EditServiceDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private serviceTypeService: ServiceDetailServiceService
  ) {}
  serviceDetailFormGroup: FormGroup;
  nameFormControl = new FormControl(this.object.name, [Validators.required]);
  descriptionControl = new FormControl(this.object.description, [
    Validators.required
  ]);

  ngOnInit() {
    this.serviceDetailFormGroup = new FormGroup({
      nameControl: this.nameFormControl,
      descriptionControl: this.descriptionControl
    });
  }

  updateServiceDetail() {
    const id = this.object._id;
    const data = {
      name: this.serviceDetailFormGroup.get("nameControl").value,
      description: this.serviceDetailFormGroup.get("descriptionControl").value
    };
    this.serviceTypeService.updateServiceDetail(id, data).subscribe(
      res => {
        this.dialogRef.close("ok");
      },
      error => {
        console.log(error);
        this.dialogRef.close("cancel");
      }
    );
  }

  cancel() {
    this.dialogRef.close("cancel");
  }
}
