import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class OptionService {
  BASE_END_POINT = environment.url;
  constructor(private http: HttpClient) { }

  getOptionsWithPagination(page: any, count: any) {
    let query =
      "?token=" +
      localStorage.getItem("token") +
      "&page=" +
      page +
      "&count=" +
      count;
    return this.http.get(this.BASE_END_POINT + "options" + query);
  }

  getAllOptions() {
    return this.http.get(
      this.BASE_END_POINT + "options?token=" + localStorage.getItem("token")
    );
  }

  createOption(data: any) {
    return this.http.post(
      this.BASE_END_POINT + "options?token=" + localStorage.getItem("token"),
      data
    );
  }

  updateOption(id: string, data: any) {
    return this.http.patch(
      this.BASE_END_POINT +
      "options/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      data
    );
  }

  deleteOption(id: string) {
    return this.http.delete(
      this.BASE_END_POINT +
      "options/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }

  activate(id: any) {
    const body = {
      is_active: true
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "options/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }

  deactivate(id: any) {
    const body = {
      is_active: false
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "options/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }
}
