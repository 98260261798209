import { Component, OnInit, ViewChild } from "@angular/core";
import { PosService } from "../pos.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";
import { AppAuthService } from "src/app/auth/auth.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-pos-edit",
  templateUrl: "./pos-edit.component.html",
  styleUrls: ["./pos-edit.component.css"]
})
export class PosEditComponent implements OnInit {
  @ViewChild("posForm") posForm: NgForm;

  constructor(
    private _posService: PosService,
    private _route: ActivatedRoute,
    private _router: Router,
    private authService: AppAuthService
  ) {}

  id = "";
  username = "";
  name = "";
  note = "";
  password = "";

  ngOnInit() {
    this._posService.editPos(this._route.snapshot.params["id"]).subscribe(
      pos => {
        this.id = pos["_id"];
        this.username = pos["user"].username;
        this.name = pos["user"].name;
        this.note = pos["note"];
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  onSubmit() {
    const pos = {
      username: this.posForm.value.username,
      name: this.posForm.value.name,
      note: this.posForm.value.note,
      password:
        this.posForm.value.password == ""
          ? undefined
          : this.posForm.value.password
    };
    console.log("pos", pos);
    this._posService.updatePos(this.id, pos).subscribe(
      () => {
        this._router.navigateByUrl("/pos");
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  onGoBack() {
    this._router.navigate(["../../"], { relativeTo: this._route });
  }
}
