import { Component, ViewChild } from "@angular/core";
import { NgForm } from "../../../../node_modules/@angular/forms";
import { Router, ActivatedRoute } from "../../../../node_modules/@angular/router";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../../app.server";
import { AppAuthService } from "../../auth/auth.service";
import { AppOrderService } from "../orders.service";
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-order-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class AppOrderEditComponent {
  categories: any[] = [];
  @ViewChild('fOrder') orderData: NgForm;
  id = '';
  name = '';
  contact_number = '';
  hotel = '';
  quantity = 0;
  amount = 0;
  purchase_date;
  use_date;


  constructor(private orderService: AppOrderService, private authService: AppAuthService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.orderService.editOrder(this.route.snapshot.params.id)
    .subscribe((order: any) => {
      this.id = order._id;
      this.name = order.buyer.name;
      this.contact_number = order.buyer.contact_number;
      this.hotel = order.buyer.hotel;
      this.quantity = order.quantity;
      this.amount = order.amount;
      this.purchase_date = dayjs(new Date(order.purchase_date)).format('YYYY-MM-DD');
      this.use_date = dayjs(new Date(order.use_date)).format('YYYY-MM-DD');
    },
    (error) => {
      if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
        this.authService.needLogin();
      }
    });
  }
  onSubmit() {
    const prepareData = {
      ...this.orderData.value,
      use_date: new Date(this.orderData.value.use_date).getTime(),
      purchase_date: new Date(this.orderData.value.purchase_date).getTime()
    }  
    this.orderService.updateOrder(this.id, prepareData)
    .subscribe(() => {
      this.router.navigateByUrl('/orders');
    },
  (error) => {
    console.log(error);
    if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
      this.authService.needLogin();
    }
  });
  }
  onGoBack() {
    this.router.navigateByUrl('/orders');
  }
  isDigit(event) {
    const inputKeyCode = event.keyCode ? event.keyCode : event.which;
    if (inputKeyCode != null) {
        if (inputKeyCode == 45) {
          event.preventDefault();
        }
    }
  }
}
