import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Subject, Observable } from "../../../node_modules/rxjs";
import { Router } from "@angular/router";
import { Order } from "./order";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AppOrderService {
  private orders: any[];
  private orderUpdated = new Subject<any[]>();
  BASE_END_POINT = environment.url;
  constructor(private http: HttpClient, private router: Router) { }

  // getOrders(): void {
  //   let series = 0;
  //   this.http.get(BASE_END_POINT + 'orders?token=' + localStorage.getItem('token'))
  //     .pipe(
  //       map((orderData: any[]) => {
  //         return orderData.map((order: any[]) => {
  //           series++;
  //           return {
  //             ...order,
  //             series: series
  //           };
  //         });
  //       })
  //     )
  //     .subscribe(transformOrders => {
  //       this.orders = transformOrders;
  //       this.orderUpdated.next([...this.orders]);
  //     },
  //   (error) => {
  //     console.log(error);
  //     if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
  //       localStorage.clear();
  //       this.router.navigateByUrl('/login');
  //     }
  //   }
  //   );
  // }

  getOrders(
    start_date: number,
    end_date: number,
    page: number,
    count: number
  ): Observable<Order[]> {
    let query = "&count=" + count + "&page=" + page;
    return this.http.get<Order[]>(
      this.BASE_END_POINT +
      "order_items?token=" +
      localStorage.getItem("token") +
      "&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      query
    );
  }

  orderUpdateListener() {
    return this.orderUpdated.asObservable();
  }
  editOrder(id: string) {
    return this.http.get(
      this.BASE_END_POINT +
      "orders/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }
  updateOrder(id: string, prepareData: Object) {
    return this.http.patch(
      this.BASE_END_POINT +
      "orders/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      prepareData
    );
  }
  deleteOrder(id: string) {
    return this.http.delete(
      this.BASE_END_POINT +
      "orders/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }
}
