import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class DestinationService {
  BASE_END_POINT = environment.url;

  constructor(private http: HttpClient) { }

  getDestinations() {
    return this.http.get(
      this.BASE_END_POINT +
      "destinations?token=" +
      localStorage.getItem("token")
    );
  }

  getDestinationsByServiceType(serviceType: any = null) {
    let query = "?token=" + localStorage.getItem("token");
    if (serviceType !== null) {
      if (serviceType == "5cd103ffffed32019012273b") {
        serviceType = "5cf893dd593f8a5b787bc55c";
      }
      query = query + "&service_type_id=" + serviceType;
    }
    return this.http.get(this.BASE_END_POINT + "destinations" + query);
  }

  getPaginateDestinations(type: String, page: number, count: number) {
    let query = "&page=" + page + "&count=" + count;
    if (type !== "") {
      query = query + "&service_type_id=" + type;
    }
    return this.http.get(
      this.BASE_END_POINT +
      "destinations?token=" +
      localStorage.getItem("token") +
      query
    );
  }

  createDestination(data: any) {
    return this.http.post(
      this.BASE_END_POINT +
      "destinations?token=" +
      localStorage.getItem("token"),
      data
    );
  }

  updateDestination(id: any, data: any) {
    return this.http.patch(
      this.BASE_END_POINT +
      "destinations/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      data
    );
  }

  deleteDestination(id: any) {
    return this.http.delete(
      this.BASE_END_POINT +
      "destinations/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }
}
