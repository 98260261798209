import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { count } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ServiceTypeService {
  BASE_END_POINT = environment.url;

  constructor(private http: HttpClient) { }

  getServiceTypes() {
    return this.http.get(
      this.BASE_END_POINT +
      "service_types?token=" +
      localStorage.getItem("token")
    );
  }

  getServiceTypesPagination(page: number, count: number) {
    let query = "&page=" + page + "&count=" + count;
    return this.http.get(
      this.BASE_END_POINT +
      "service_types?token=" +
      localStorage.getItem("token") + query
    );
  }

  createServiceType(data: any) {
    return this.http.post(
      this.BASE_END_POINT +
      "service_types?token=" +
      localStorage.getItem("token"),
      data
    );
  }

  updateServiceType(id: any, data: any) {
    return this.http.patch(
      this.BASE_END_POINT +
      "service_types/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      data
    );
  }

  deleteServiceType(id: any) {
    return this.http.delete(
      this.BASE_END_POINT +
      "service_types/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }
}
