import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class DashboardService {
  constructor(private http: HttpClient) { }
  BASE_END_POINT = environment.url;
  public getMonthlyStatistics() {
    let query = "&service_type=5cd103ceffed32019012273a"
    return this.http.get(
      `${
      this.BASE_END_POINT
      }charts/monthly?${query}&token=${localStorage.getItem("token")}`
    );
  }

  public getTodayStatistics() {
    let query = "&service_type=5cd103ceffed32019012273a";
    return this.http.get(
      `${this.BASE_END_POINT}charts/today?${query}&token=${localStorage.getItem(
        "token"
      )}`
    );
  }

  public getDailyStatistics() {
    let query = "&service_type=5cd103ceffed32019012273a";
    return this.http.get(
      `${this.BASE_END_POINT}charts/daily?${query}&token=${localStorage.getItem(
        "token"
      )}`
    );
  }

  public getWeeklyStatistics(weekCount: any) {
    return this.http.get(
      `${this.BASE_END_POINT}charts/weekly?token=${localStorage.getItem(
        "token"
      )}&week_num=${weekCount}`
    );
  }

  public getMonthlyServiceIncome() {
    return this.http.get(
      `${this.BASE_END_POINT}charts/monthly_service_income?token=${localStorage.getItem(
        "token"
      )}`
    );
  }

  public getDailyServiceIncome() {
    return this.http.get(
      `${this.BASE_END_POINT}charts/daily_service_income?token=${localStorage.getItem(
        "token"
      )}`
    );
  }
}
