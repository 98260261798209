import { Injectable } from "@angular/core";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../app.server";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Subject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { Ticket } from "./services";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AppTicketService {
  private tickets: any[];
  private ticketUpdated = new Subject<any[]>();
  BASE_END_POINT = environment.url;
  constructor(private http: HttpClient, private router: Router) { }

  getServices(type: any, page: number, count: number): Observable<Ticket[]> {
    let query =
      "?token=" +
      localStorage.getItem("token") +
      "&count=" +
      count +
      "&page=" +
      page;

    if (type !== "") {
      query = query + "&service_type_id=" + type;
    }

    return this.http.get<Ticket[]>(
      this.BASE_END_POINT + "services/admin" + query
    );
  }

  getAllServices() {
    return this.http.get<Ticket[]>(
      this.BASE_END_POINT +
      "services/admin?token=" +
      localStorage.getItem("token")
    );
  }

  getServicesByType(service_type_id: string) {
    return this.http.get(
      this.BASE_END_POINT +
      "services/admin?token=" +
      localStorage.getItem("token") +
      "&service_type_id=" +
      service_type_id
    );
  }

  ticketUpdateListener() {
    return this.ticketUpdated.asObservable();
  }
  getCategories() {
    return this.http.get(
      this.BASE_END_POINT + "categories?token=" + localStorage.getItem("token")
    );
  }
  addTicket(ticket, images) {
    const ticketData = new FormData();
    ticketData.append("service_type_id", ticket.service_type_id);
    ticketData.append("service_detail_id", ticket.service_detail_id);
    ticketData.append("link", ticket.link);
    ticketData.append("total", ticket.total);
    // ticketData.append("merchant_id", ticket.merchant_id);
    ticketData.append("title", ticket.title);
    ticketData.append("detail", ticket.detail);
    for (var i = 0; i < images.length; i++) {
      ticketData.append("feature_image", images[i]);
    }

    console.log("ticket data: ", ticket);
    return this.http.post(
      this.BASE_END_POINT + "services?token=" + localStorage.getItem("token"),
      ticketData
    );
  }
  getTicketService(id) {
    return this.http.get(
      this.BASE_END_POINT +
      "services/admin/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }
  updateTicket(ticket, id, images, deleted_imgs, files) {
    const ticketData = new FormData();
    ticketData.append("service_type_id", ticket.value.service_type);
    ticketData.append("service_detail_id", ticket.value.service_detail);
    ticketData.append("destination", ticket.value.destination);
    ticketData.append("link", ticket.value.link);
    ticketData.append("total", ticket.value.total);
    ticketData.append("title", ticket.value.title);
    ticketData.append("detail", ticket.value.detail);
    if (images != undefined) {
      for (var i = 0; i < images.length; i++) {
        ticketData.append("mv_position_img", images[i].file_index);
      }
      if (images.length === 1) {
        ticketData.append("mv_position_img", "");
      }
    }

    if (files != undefined) {
      for (let i = 0; i < files.length; i++) {
        ticketData.append("feature_image", files[i]);
      }
    }

    if (deleted_imgs != undefined) {
      for (let i = 0; i < deleted_imgs.length; i++) {
        ticketData.append("delete_list", deleted_imgs[i].file);
      }
      ticketData.append("delete_list", ""); // add blank data to make this feild become array.
    }

    console.log(ticketData);
    return this.http.patch(
      this.BASE_END_POINT +
      "services/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      ticketData
    );
  }
  deleteTicket(id) {
    return this.http.delete(
      this.BASE_END_POINT +
      "services/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }

  getServiceTypes() {
    return this.http.get(
      this.BASE_END_POINT +
      "service_types?token=" +
      localStorage.getItem("token")
    );
  }

  getServiceDetails() {
    return this.http.get(
      this.BASE_END_POINT +
      "service_details?token=" +
      localStorage.getItem("token")
    );
  }

  activate(id: string) {
    const body = {
      active_service: true
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "services/activate_deactivate/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }

  deactivate(id: string) {
    const body = {
      active_service: false
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "services/activate_deactivate/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }
}
