import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppAuthService } from "../auth/auth.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class AppHomeComponent implements OnInit {
  isDashboard = false;
  logo_link = "";
  role = "";
  isAdmin = false;
  footer_year = "";
  constructor(private router: Router, private authService: AppAuthService) {}
  ngOnInit() {
    if (localStorage.getItem("role") === "System Admin") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    this.footer_year = new Date().getFullYear().toString();
    document.body.className = "hold-transition skin-blue sidebar-mini";
    this.logo_link = localStorage.getItem("logo_link");
    this.role = this.authService.getRole();
    // if (this.router.url === "/") {
    //   this.router.navigateByUrl("/providers");
    // }
  }
  onLogout() {
    this.authService.logout();
  }

  // ngAfterViewInit() {
  //   $('[data-widget="treeview"]').each(function() {
  //     AdminLte.Treeview._jQueryInterface.call($(this), "init");
  //   });
  // }
}
