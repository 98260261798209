import { Component, OnInit, ViewChild } from '@angular/core';
import { MerchantService } from 'src/app/merchants/merchant.service';
import { TOKEN_EXPIRED, INVALID_TOKEN } from 'src/app/app.server';
import { AppAuthService } from 'src/app/auth/auth.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sub-merchant-new',
  templateUrl: './sub-merchant-new.component.html',
  styleUrls: ['./sub-merchant-new.component.css']
})
export class SubMerchantNewComponent implements OnInit {

  constructor(private merchantService: MerchantService, private authService: AppAuthService, private _router: Router, private _route: ActivatedRoute) { }

  parents: any;
  selectedParent: any;
  selectedImage: File
  nothingSelected = true;
  permissionArray = [];
  username = '';
  name = '';
  phone_number = '';
  email = '';
  website = '';
  address = '';
  password = '';
  logo: any;

  @ViewChild('merchantForm') merchantForm: NgForm;


  ngOnInit() {
    this.getMerchants();
  }

  getMerchants(){
    this.merchantService.getMerchants().subscribe(
      (merchants: any) => {
        if (merchants.length > 0){
          this.parents = [];
          this.parents = merchants;
          this.selectedParent = this.parents[0]._id;
        }
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    )
  }

  onChange(event){
  }


  onSubmit(){

    this.getCheckedPermissions();
    const formData = new FormData();
    formData.append('address', this.merchantForm.value.address);
    formData.append('logo', this.selectedImage);
    formData.append('email', this.merchantForm.value.email);
    formData.append('username', this.merchantForm.value.username);
    if (this.selectedParent !== "0" && !undefined) formData.append('parent', this.selectedParent);
    formData.append('name', this.merchantForm.value.name);
    formData.append('website', this.merchantForm.value.website);
    for (var i = 0 ; i < this.permissionArray.length; i++){
      formData.append('permissions', this.permissionArray[i]);
    }
    formData.append('phone_number', this.merchantForm.value.phone_number);
    formData.append('password', this.merchantForm.value.password);
    this.merchantService.addMerchant(formData)
    .subscribe(()=>{
      this._router.navigateByUrl('/sub-merchants');
    },
    (error)=>{
      if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
        this.authService.needLogin();
      }
      
    });
  }

  imgSrc: any;

  getFile(event){

    this.validateCheckbox();
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => { this.imgSrc = reader.result; }


      reader.readAsDataURL(file);
    }
  }

  validateCheckbox(){
    let checkboxes = document.getElementsByClassName('permission');
    for (var i = 0; i < checkboxes.length; i++){
      if (checkboxes.item(i)['checked']){
        this.nothingSelected = false;
        return;
      }
      else{
        this.nothingSelected = true;
      }
    }
  }

  getCheckedPermissions(){
    let checkboxes = document.getElementsByClassName('permission');
    this.permissionArray = [];
    for (var i = 0; i < checkboxes.length; i++){
      if (checkboxes.item(i)['checked']){
        this.permissionArray.push(checkboxes.item(i)['value']);
      }
    }
    console.log(this.permissionArray);
  }

  onGoBack() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }
}
