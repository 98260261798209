import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { User } from "./user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class UserService {
  BASE_END_POINT = environment.url;
  constructor(private http: HttpClient) { }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(
      this.BASE_END_POINT + "users?token=" + localStorage.getItem("token")
    );
  }

  getUsersPagination(page: number, count: number): Observable<User[]> {
    let query = "&page=" + page + "&count=" + count;
    return this.http.get<User[]>(
      this.BASE_END_POINT + "users?token=" + localStorage.getItem("token") + query
    );
  }

  addUser(user: any) {
    return this.http.post(
      this.BASE_END_POINT + "users?token=" + localStorage.getItem("token"),
      user
    );
  }

  editUser(id: string) {
    return this.http.get(
      this.BASE_END_POINT +
      "users/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }

  updateUser(id: string, user: any) {
    return this.http.patch(
      this.BASE_END_POINT +
      "users/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      user
    );
  }

  deleteUser(id: string) {
    return this.http.delete(
      this.BASE_END_POINT +
      "users/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }

  getRoles() {
    return this.http.get(
      this.BASE_END_POINT + "roles?token=" + localStorage.getItem("token")
    );
  }
}
