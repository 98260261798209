import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppAuthService } from "../auth/auth.service";
import { MatSnackBar } from "@angular/material";

@Injectable({ providedIn: "root" })
export class HomeGuardService implements CanActivate {
  constructor(private router: Router, private route: ActivatedRoute, private authService: AppAuthService, private snackBar: MatSnackBar) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | Observable<boolean> | boolean {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem('role');
    const currentMill = new Date().getTime();
    const expiresAt = Number(localStorage.getItem('expiresAt'));

    if (!token || !role) {
      this.router.navigateByUrl('/login');
    }

    if (currentMill >= expiresAt) {
      this.showSnackBar('Session Expired! Login again.');
      this.authService.logout();
      return false;
    }
    return true;
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
      panelClass: ["red-snackbar"],
      horizontalPosition: "right"
    });
  }
}
