import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { HistoryService } from "../history.service";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";
import { AppAuthService } from "src/app/auth/auth.service";
import { SelectionModel } from "@angular/cdk/collections";
import { NgxDrpOptions, PresetItem, Range } from "ngx-mat-daterange-picker";
import { MerchantService } from "src/app/merchants/merchant.service";

@Component({
  selector: "app-history-list",
  templateUrl: "./history-list.component.html",
  styleUrls: ["./history-list.component.css"]
})
export class HistoryListComponent implements OnInit {
  selectedMerchant = {
    _id: "0",
    address: "",
    email: "",
    index: 0,
    is_active: 0,
    logo: "",
    name: "All",
    phone_number: "",
    username: "",
    wallet: 0,
    website: ""
  };
  merchants = [];
  displayedColumns = [
    "index",
    "merchant_name",
    "amount",
    "note",
    "created_at",
    "action"
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("dateRangePicker") dateRangePicker;
  isLoading = true;

  username = "";
  merchantName = "";
  date: number;
  totalPrice: number;
  claimer = "";
  totalAmount = 0;
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];

  range: Range = { fromDate: new Date(), toDate: new Date() };
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];

  constructor(
    private _merchantService: MerchantService,
    private _historyService: HistoryService,
    private authService: AppAuthService
  ) { }

  ngOnInit() {
    const today = new Date();
    const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);

    const currMonthStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const currMonthEnd = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    const resetRange = { fromDate: currMonthStart, toDate: currMonthEnd };
    this.dateRangePicker.resetDates(resetRange);

    this.setupPresets();
    this.options = {
      presets: this.presets,
      format: "mediumDate",
      range: { fromDate: currMonthStart, toDate: currMonthEnd },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false,
        hasBackdrop: false
      },
      cancelLabel: "Cancel",
      excludeWeekends: false,
      fromMinMax: { fromDate: fromMin, toDate: fromMax },
      toMinMax: { fromDate: toMin, toDate: toMax }
    };

    this._merchantService.getMerchants().subscribe(
      data => {
        const array1 = [
          {
            _id: "0",
            address: "",
            email: "",
            index: 0,
            is_active: 0,
            logo: "",
            name: "All",
            phone_number: "",
            username: "",
            wallet: 0,
            website: ""
          }
        ];
        const array2 = data;
        const totalArray = array1.concat(array2);
        this.merchants = totalArray;
        this.selectedMerchant = this.merchants[0];
        this.dataSource.data = [];
        this.isLoading = true;
        this.reload(this.currentPage);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );

    this.reload(this.currentPage);
  }

  setupPresets() {
    const backDate = numOfDays => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {
        presetLabel: "Yesterday",
        range: { fromDate: yesterday, toDate: today }
      },
      {
        presetLabel: "Last 7 Days",
        range: { fromDate: minus7, toDate: today }
      },
      {
        presetLabel: "Last 30 Days",
        range: { fromDate: minus30, toDate: today }
      },
      {
        presetLabel: "This Month",
        range: { fromDate: currMonthStart, toDate: currMonthEnd }
      },
      {
        presetLabel: "Last Month",
        range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      }
    ];
  }

  updateRange(range: Range) {
    this.range = range;
    // this.dataSource.data = [];
    // this.selection = new SelectionModel<any>(true, []);
    // this.isLoading = true;
    // this._merchantService
    //   .getMerchantPayments(
    //     this.selectedMerchant["_id"],
    //     this.range.fromDate.getTime(),
    //     this.range.toDate.getTime()
    //   )
    //   .subscribe(
    //     data => {
    //       this.isLoading = false;
    //       this.dataSource.data = data;
    //     },
    //     error => {
    //       if (
    //         error.error.message === TOKEN_EXPIRED ||
    //         error.error.message === INVALID_TOKEN
    //       ) {
    //         this.authService.needLogin();
    //       }
    //     }
    //   );
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    this.reload(this.currentPage);
  }

  changeValue(event) {
    this.selectedMerchant = event;
    this.reload(this.currentPage);
  }

  onShowPrintLog(log) {
    // console.log(log);

    this.username = localStorage.getItem("username");
    this.merchantName = log["merchant_name"];
    this.date = log["created_at"];
    this.totalPrice = log["amount"];
    this.claimer = log["claimer"];
  }

  reload(page: any) {
    if (page) {
      this.currentPage = page;
    }
    this.dataSource.data = [];
    this.isLoading = true;
    this._historyService
      .getLogs(
        this.selectedMerchant["_id"],
        this.range.fromDate.toISOString(),
        this.range.toDate.toISOString(),
        this.currentPage,
        this.count
      )
      .subscribe(
        data => {
          this.isLoading = false;
          this.totalAmount = 0;
          this.dataSource.data = data;
          data.forEach(element => {
            this.totalAmount = this.totalAmount + element["amount"];
          });
        },
        error => {
          this.isLoading = false;
          if (
            error.error.message === TOKEN_EXPIRED ||
            error.error.message === INVALID_TOKEN
          ) {
            this.authService.needLogin();
          }
        }
      );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onPrint() {
    const WindowPrt = window.open("", "PRINT", "height=700,width=900");

    var header =
      `
    <html><head>
    <title>` +
      document.title +
      `</title>
    <style>
    #print_container {
      width: 80% !important;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5%;
      margin-bottom: 5%;
      transition: 0.3s;
    }
    
    .logo {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      align-content: center;
    }
    
    .logo img {
      width: 40%;
      margin: auto;
      display: block;
    }
    
    .title {
      text-align: center;
    }
    
    .title h3 {
      margin: 10px 0 5px 0;
    }
    
    .summary {
      width: 100%;
    }
    
    .summary .cashier {
      float: left;
    }
    
    .summary .cashier p {
      margin: 0%;
      font-weight: bold;
    }
    
    
    .summary .date {
      float: right;
    }
    
    .summary .date P {
      margin: 0%;
      font-weight: bold;
    }
    
    .clear {
      clear: both;
    }
    
    
    #payment {
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #000;
      margin-top: 5px;
    }
    
    #payment th {
      background: #1960A0;
      color: #ffffff;
      text-align: left;
      padding: 7px;
      -webkit-print-color-adjust: exact;
    }
    
    #payment td {
      border: 1px solid grey;
      padding: 3px;
    }
    
    
    .signature {
      margin-top: 25px;
    }
    
    .signature .cashier-side {
      width: 50%;
      float: left;
      text-align: center;
    }
    
    .signature .merchant-side {
      width: 50%;
      float: right;
      text-align: center
    }
    
    .line {
      width: 60%;
      padding-top: 70px;
      margin: auto;
      border-bottom: 1px solid #000;
    }
    
    .total_price_footer{
        font-weight: bold;
    }
    
    #total_footer{
        text-align: end;
    }
    
    </style>
    `;
    var content = header + document.getElementById("container").innerHTML;

    WindowPrt.document.write(content);
    WindowPrt.document.write("</body></html>");
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

  /** Function for pagination */
  onOptionCountChange() {
    // console.log("event");
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
