import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MerchantServiceService } from "../merchant-service.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DestinationService } from "src/app/destination/destination.service";
import { ServiceTypeService } from "src/app/service-type/service-type.service";
@Component({
  selector: "app-create-merchant-service-dialog",
  templateUrl: "./create-merchant-service-dialog.component.html",
  styleUrls: ["./create-merchant-service-dialog.component.css"]
})
export class CreateMerchantServiceDialogComponent implements OnInit {
  merchants: any;
  services: any;
  serviceTypes: any;
  // destinations: any;
  // defaultDestination: any;
  selectedService: any;
  selectedMerchant: any;
  // selectedDestination: any;
  selectedServiceType: any;
  constructor(
    public dialogRef: MatDialogRef<CreateMerchantServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private merchantServiceService: MerchantServiceService,
    private destinationService: DestinationService,
    private serviceTypeService: ServiceTypeService
  ) { }

  merchantServiceFormGroup: FormGroup;
  merchantFormControl = new FormControl("", [Validators.required]);
  serviceFormControl = new FormControl("", [Validators.required]);
  descriptionControl = new FormControl("", [Validators.required]);
  serviceTypeControl = new FormControl("", [Validators.required]);
  // originalPriceControl = new FormControl("", [Validators.required]);
  // guidePriceControl = new FormControl("", [Validators.required]);
  // touristPriceControl = new FormControl("", [Validators.required]);
  // commissionControl = new FormControl("", [Validators.required]);
  destinationControl = new FormControl("", [Validators.required]);

  getRequiredErrorMessage(field) {
    return this.merchantServiceFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  ngOnInit() {
    this.merchantServiceFormGroup = new FormGroup({
      serviceControl: this.serviceFormControl,
      merchantControl: this.merchantFormControl,
      descriptionControl: this.descriptionControl,
      // originalPriceControl: this.originalPriceControl,
      // guidePriceControl: this.guidePriceControl,
      // touristPriceControl: this.touristPriceControl,
      serviceTypeControl: this.serviceTypeControl,
      // commissionControl: this.commissionControl,
      // destinationControl: this.destinationControl
    });
    this.merchantServiceFormGroup
      .get("descriptionControl")
      .setValue("description");
    // this.merchantServiceFormGroup.get("commissionControl").setValue(0);
    this.getServiceTypes();
  }

  getMerchants() {
    this.merchants = [];
    this.merchantServiceService
      .getMerchantsByServiceType(this.selectedServiceType._id)
      .subscribe(
        res => {
          this.merchants = res;
          this.selectedMerchant = this.merchants[0];
          // this.merchantFormControl.setValue(this.merchants[0]._id);
        },
        error => {
          console.log(error);
        }
      );
  }

  getServices() {
    this.services = [];
    this.merchantServiceService
      .getServicesByType(this.selectedServiceType._id)
      .subscribe(
        res => {
          this.services = res;
          this.selectedService = this.services[0];
          // this.serviceFormControl.setValue(this.services[0]._id);
        },
        error => {
          console.log(error);
        }
      );
  }

  onServiceChange(event) {
    this.selectedService = event;
  }

  onMerchantChange(event) {
    this.selectedMerchant = event;
  }

  // onDestinationChange(event) {
  //   this.selectedDestination = event;
  // }

  onServiceTypeChange(event) {
    this.selectedServiceType = event;
    // this.getDestinations();
    this.getMerchants();
    this.getServices();
  }

  createMerchantService() {
    const data = {
      merchant: this.selectedMerchant._id,
      service: this.selectedService._id,
      description: this.merchantServiceFormGroup.get("descriptionControl")
        .value,
      // original_price: this.merchantServiceFormGroup.get("originalPriceControl").value,
      // destination: this.selectedDestination._id,
      // guide_price: this.merchantServiceFormGroup.get("guidePriceControl").value,
      // tourist_price: this.merchantServiceFormGroup.get("touristPriceControl").value,
      // commission: this.merchantServiceFormGroup.get("commissionControl").value,
      service_type: this.selectedServiceType._id
    };
    console.log(data);
    this.merchantServiceService.createMerchantService(data).subscribe(
      res => {
        this.dialogRef.close(1);
      },
      error => {
        console.log(error);
        this.dialogRef.close(0);
      }
    );
  }

  cancel() {
    this.dialogRef.close(0);
  }

  // getDestinations() {
  //   this.destinationService
  //     .getDestinationsByServiceType(this.selectedServiceType._id)
  //     .subscribe(
  //       res => {
  //         this.destinations = res;
  //         this.selectedDestination = this.destinations[0];
  //       },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  // }

  getServiceTypes() {
    this.serviceTypes = [];
    this.serviceTypeService.getServiceTypes().subscribe(
      (res: any) => {
        this.serviceTypes = res;
        this.selectedServiceType = this.serviceTypes[0];
        // this.getDestinations();
        this.getMerchants();
        this.getServices();
      },
      err => {
        console.log(err);
      }
    );
  }
}
