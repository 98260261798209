import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "merchantFilterByType"
})
export class MerchantFilterByTypePipe implements PipeTransform {
  transform(pipeData, pipeModifier) {
    console.log("pipeModifier: ", pipeModifier);

    if (pipeData !== undefined && pipeData !== null) {
      return pipeData.filter(item => {
        return item["service_type"]["_id"].includes(pipeModifier);
      });
    }
  }
}
