import { Component, ViewChild } from "@angular/core";
import {
  NgForm,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../../app.server";
import { AppAuthService } from "../../auth/auth.service";
import { AppTicketService } from "../services.service";
import { MerchantService } from "src/app/merchants/merchant.service";

@Component({
  selector: "app-ticket-new",
  templateUrl: "./new.component.html",
  styleUrls: ["./new.component.css"]
})
export class AppTicketNewComponent {
  serviceTypes: any[] = [];
  serviceDetails: any[] = [];
  @ViewChild("fTicket") ticketData: NgForm;
  link = "http://www.";
  previewFile = "";
  defaultServiceType = "";
  defaultServiceDetail = "";
  feature_image: File;
  fileText = "";
  inputFile = null;
  total: any;

  merchants: any[];
  imageArray: any;
  selectedMerchant: any;

  constructor(
    private _merchantService: MerchantService,
    private ticketsService: AppTicketService,
    private authService: AppAuthService,
    private router: Router
  ) { }

  createServiceFormGroup: FormGroup;
  titleControl = new FormControl("", [Validators.required]);
  serviceTypeControl = new FormControl("", [Validators.required]);
  serviceDetailControl = new FormControl("", [Validators.required]);
  linkControl = new FormControl("");
  detailControl = new FormControl("", [Validators.required]);
  // merchantControl = new FormControl("", [Validators.required]);
  // totalControl = new FormControl("", [Validators.required]);
  fileControl = new FormControl("", [Validators.required]);

  ngOnInit() {
    this.createServiceFormGroup = new FormGroup({
      titleControl: this.titleControl,
      serviceTypeControl: this.serviceTypeControl,
      serviceDetailControl: this.serviceDetailControl,
      linkControl: this.linkControl,
      detailControl: this.detailControl,
      // merchantControl: this.merchantControl,
      // totalControl: this.totalControl,
      fileControl: this.fileControl
    });
    this.ticketsService.getServiceTypes().subscribe(
      (serviceTypes: any[]) => {
        this.defaultServiceType = serviceTypes[0]["_id"];
        this.serviceTypes = serviceTypes;
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
    this.ticketsService.getServiceDetails().subscribe(
      (serviceDetails: any[]) => {
        this.defaultServiceDetail = serviceDetails[0]["_id"];
        this.serviceDetails = serviceDetails;
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
    // this.getMerchants();
  }

  // getMerchants(service_type = undefined) {
  //   this._merchantService.getMerchants(service_type).subscribe(
  //     data => {
  //       this.merchants = data;
  //       this.selectedMerchant = this.merchants[0];
  //     },
  //     error => {
  //       if (
  //         error.error.message === TOKEN_EXPIRED ||
  //         error.error.message === INVALID_TOKEN
  //       ) {
  //         this.authService.needLogin();
  //       }
  //     }
  //   );
  // }
  onFileChanged(event) {
    console.log(this.inputFile);

    this.feature_image = event.target.files;
    var files = event.target.files;
    var output = document.getElementById("imagePreview");
    for (var i = 0; i < files.length; i++) {
      // var file = files[i];
      // var reader = new FileReader();
      // reader.onload = (event: ProgressEvent) => {
      //   this.srcArray.push((<FileReader>event.target).result);
      // };
      // reader.readAsDataURL(file);
      // var picReader = new FileReader();
      // picReader.addEventListener("load", function (event) {
      //   var picFile = event.target;
      //   var div = document.createElement("div");
      //   div.style.marginRight = '5px';
      //   div.innerHTML = "<img src='" + picFile['result'] + "'" + "title='" + file.name + "' width='100px' height='100px'/>";
      //   output.insertBefore(div, null);
      // });
      // picReader.readAsDataURL(file);

      let file = files[i];
      let reader = new FileReader();
      let context = this;
      context.imageArray = [];
      reader.readAsDataURL(file);
      reader.onload = function () {
        context.imageArray.push({
          img: reader.result,
          name: file.name,
          file: file
        });
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
    console.log(this.imageArray);
  }

  deleteImage(item: any) {
    const index = this.imageArray.indexOf(item);
    if (index > -1) {
      this.imageArray.splice(index, 1);
    }
    if (this.imageArray.length <= 0) {
      this.fileControl.setValue(null);
    }
  }

  onSubmit() {
    let files = [];
    for (let i = 0; i < this.imageArray.length; i++) {
      files.push(this.imageArray[i].file);
    }
    const ticketData = {
      service_type_id: this.serviceTypeControl.value,
      service_detail_id: this.serviceDetailControl.value,
      link: this.linkControl.value,
      total: 0,
      // merchant_id: this.selectedMerchant._id,
      title: this.titleControl.value,
      detail: this.detailControl.value
    };
    this.ticketsService.addTicket(ticketData, files).subscribe(
      () => {
        this.router.navigateByUrl("/services");
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }
  onGoBack() {
    this.router.navigateByUrl("/services");
  }
  isDigit(event) {
    const inputKeyCode = event.keyCode ? event.keyCode : event.which;
    if (inputKeyCode != null) {
      if (inputKeyCode == 45) {
        event.preventDefault();
      }
    }
  }

  onServiceTypeChange(event) {
    // this.getMerchants(event.target.value);
    // this.merchantControl.setValue("");
    if (event.target.value === "5cd103ffffed32019012273b") {
      // this.totalControl.setValue(1);
      this.total = 1;
    } else {
      // this.totalControl.setValue(0);
      this.total = 0;
    }
  }
}
