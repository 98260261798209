import { Component, OnInit, ViewChild } from "@angular/core";
import { AppAuthService } from "./auth.service";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";

@Component({
  selector: "app-login",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.css"]
})
export class AppLoginComponent implements OnInit {
  @ViewChild("fAuth") authData: NgForm;
  loginInvalid = "";
  isLoading = false;
  constructor(private authService: AppAuthService, private router: Router, private snackBar: MatSnackBar) { }
  ngOnInit() {
    document.body.className = "hold-transition login-page";
  }
  onSubmit() {
    this.isLoading = true;
    this.authService
      .login(this.authData.value.user_name, this.authData.value.password)
      .subscribe(
        response => {
          if (response["success"]) {
            this.authService.setToken(response["data"]["token"]);
            const logo_link = response["data"]["logo"];
            const role = response["data"]["role"]["name"];
            const username = response['data']['username'];
            const userId = response["data"]["_id"];
            const expiresAt = response["data"]["expiresAt"];
            if (this.authService.getToken()) {
              this.authService.saveAuthData(username,
                this.authService.getToken(),
                logo_link,
                role,
                userId,
                expiresAt
              );
              this.authService.setAuth(true);
              this.isLoading = false;
              if (role == "System Admin") {
                this.router.navigateByUrl("/dashboard");
                // this.router.navigate(['/dashboard']);
              } else if (role == "POS Manager") {
                this.router.navigateByUrl("/sale_shifts");
              }
              else {
                this.showSnackBar("Permission not allow. Please contact administrator");
                this.authService.logout();
              }
            }
          } else {
            this.showSnackBar(response['message']);
            // this.authData.resetForm();
            this.isLoading = false;
          }
        },
        error => {
          console.log(error);
          this.loginInvalid = "Invalid username or password";
          // this.authData.resetForm();
          this.isLoading = false;
        }
      );
  }
  showSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
      panelClass: ["red-snackbar"],
      horizontalPosition: "right"
    });
  }
}
