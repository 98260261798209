import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MerchantService } from "../merchant.service";
import { MatTableDataSource, MatPaginator, MatSort } from "@angular/material";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";
import { AppAuthService } from "src/app/auth/auth.service";
import { NgForm, FormGroup, FormControl, Validators } from "@angular/forms";
import { NotificationService } from "src/shared/notification.service";
import { ServiceTypeService } from "src/app/service-type/service-type.service";

@Component({
  selector: "app-merchant-list",
  templateUrl: "./merchant-list.component.html",
  styleUrls: ["./merchant-list.component.css"]
})
export class MerchantListComponent implements OnInit {
  constructor(
    private _merchantService: MerchantService,
    private authService: AppAuthService,
    private notificationService: NotificationService,
    private serviceTypeService: ServiceTypeService
  ) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("clearForm") clearForm: NgForm;

  @ViewChild("logtSort") logSort: MatSort;

  deleteId = "";
  isLoading = true;
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 60];

  // query = {};

  displayedColumns = [
    "index",
    "username",
    "name",
    "service_type",
    "phone_number",
    "email",
    // "website",
    // "address",
    // "language",
    "action"
  ];
  logDisplayedColumns = ["index_log", "amount", "note", "created_at"];
  dataSource = new MatTableDataSource();
  logDataSource = new MatTableDataSource();

  merchantName = "";
  merchantWallet = "";
  note = "";
  clearId = "";
  merchantNameLog = "";
  serviceTypes = [];
  selectedType = "";



  ngOnInit() {

    this.reload(this.currentPage);
    this.getServiceTypes();

    console.log(localStorage.getItem("role"));
    // console.log(this.dataSource.data);
  }

  onOptionCountChange() {
    // console.log("event");
    // console.log(event.target.value);
    // this.count = event.target.value;
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  confirmDelete(id: string) {
    console.log("merchant_id: ", id);
    this.deleteId = id;
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }

  // confirmClear(id: string) {
  //   this.clearId = id;
  //   this._merchantService.getAMerchant(this.clearId).subscribe(
  //     data => {
  //       this.merchantName = data["user"].name;
  //       this.merchantWallet = data["wallet"];
  //     },
  //     error => {
  //       console.log(error);
  //       if (
  //         error.error.message === TOKEN_EXPIRED ||
  //         error.error.message === INVALID_TOKEN
  //       ) {
  //         this.authService.needLogin();
  //       }
  //     }
  //   );
  // }

  onDelete() {
    this._merchantService.deleteMerchant(this.deleteId).subscribe(
      res => {
        console.log("response: ", res);
        this.dataSource.data = [];
        this.isLoading = true;
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  // onSubmit(){
  //   this.note = this.clearForm.value.note;
  //   this._merchantService.clearWallet(this.clearId,Number(this.merchantWallet), this.note).subscribe(
  //     ()=>{
  //       this._merchantService.getMerchants().subscribe(
  //         (data) =>  {this.dataSource.data = data;},
  //         (error) => {
  //           console.log(error);
  //           if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
  //             this.authService.needLogin();
  //           }
  //         }
  //       );
  //       this.note = '';
  //     },
  //     (error) => {
  //       console.log(error);
  //       if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
  //         this.authService.needLogin();
  //       }
  //     }
  //   )
  // }

  showLogs(id: string) {
    this._merchantService.getAMerchant(id).subscribe(
      data => {
        this.merchantNameLog = data["user"].name;
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
    this._merchantService.getLogs(id).subscribe(
      data => {
        console.log(data);

        this.logDataSource.data = data;
        this.logDataSource.sort = this.logSort;
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  reload(page: number) {
    this.dataSource.data = [];
    this.isLoading = true;
    if (page) {
      this.currentPage = page;
    }

    this._merchantService
      .getMerchantByTypeAndPaginate(
        this.currentPage,
        this.count,
        this.selectedType
      )
      .subscribe(
        (data: any) => {
          this.isLoading = false;
          // console.log("merchant data: ", data);
          this.dataSource.data = data;
          // console.log(this.dataSource.data);
        },
        error => {
          console.log(error);
          this.isLoading = false;
          if (
            error.error.message === TOKEN_EXPIRED ||
            error.error.message === INVALID_TOKEN
          ) {
            this.authService.needLogin();
          }
        }
      );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  activate(id: any) {
    this._merchantService.activateMerchant(id).subscribe(
      res => {
        this.notificationService.showSuccess("Merchant activated successfully");
        this.reload(this.currentPage);
      },
      err => {
        this.notificationService.showError("Merchant activation failed!");
      }
    );
  }

  deactivate(id: any) {
    this._merchantService.deactivateMerchant(id).subscribe(
      res => {
        this.notificationService.showSuccess(
          "Merchant deactivated successfully"
        );
        this.reload(this.currentPage);
      },
      err => {
        this.notificationService.showError("Merchant deactivation failed!");
      }
    );
  }
  getServiceTypes() {
    this.serviceTypes = [];
    this.serviceTypeService.getServiceTypes().subscribe(
      (res: any) => {
        this.serviceTypes = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  onTypeChange() {
    this.currentPage = 1;
    this.reload(this.currentPage);
  }
}
