import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DestinationService } from "src/app/destination/destination.service";
import { AppTicketService } from "src/app/services/services.service";
import { DestinationServiceService } from "../destination-service.service";
import { ServiceTypeService } from "src/app/service-type/service-type.service";

@Component({
  selector: "app-create-destination-service-dialog",
  templateUrl: "./create-destination-service-dialog.component.html",
  styleUrls: ["./create-destination-service-dialog.component.css"]
})
export class CreateDestinationServiceDialogComponent implements OnInit {
  destinations: any;
  selectedDestination: any;
  services: any;
  selectedService: any;
  serviceTypes: any;
  selectedServiceType: any;
  constructor(
    public dialogRef: MatDialogRef<CreateDestinationServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private destinationService: DestinationService,
    private ticketService: AppTicketService,
    private destinationServiceService: DestinationServiceService,
    private serviceTypeService: ServiceTypeService
  ) { }

  createDestinationServiceFormGroup: FormGroup;
  serviceTypeControl = new FormControl("", [Validators.required]);
  destinationControl = new FormControl("", [Validators.required]);
  serviceControl = new FormControl("", [Validators.required]);
  descriptionControl = new FormControl("");

  ngOnInit() {
    this.createDestinationServiceFormGroup = new FormGroup({
      serviceTypeControl: this.serviceTypeControl,
      destinationControl: this.destinationControl,
      serviceControl: this.serviceControl,
      descriptionControl: this.descriptionControl
    });
    this.getServiceTypes();
  }

  onCancel() {
    this.dialogRef.close();
  }

  getDestinations() {
    this.destinations = [];
    this.destinationService
      .getDestinationsByServiceType(this.selectedServiceType._id)
      .subscribe(
        (res: any) => {
          this.destinations = res;
          this.selectedDestination = this.destinations[0];
        },
        err => {
          console.log(err);
        }
      );
  }

  getServices() {
    this.services = [];
    this.ticketService
      .getServicesByType(this.selectedServiceType._id)
      .subscribe(
        (res: any) => {
          this.services = res;
          this.selectedService = this.services[0];
        },
        err => {
          console.log(err);
        }
      );
  }

  getServiceTypes() {
    this.serviceTypeService.getServiceTypes().subscribe(
      (res: any) => {
        this.serviceTypes = res;
        this.selectedServiceType = this.serviceTypes[0];
        this.getServices();
        this.getDestinations();
      },
      err => {
        console.log(err);
      }
    );
  }

  onDestinationChange(event) {
    this.descriptionControl.setValue(this.selectedService.title + " to " + this.selectedDestination.name);
  }

  onServiceChange(event) {
    this.descriptionControl.setValue(this.selectedService.title + " to " + this.selectedDestination.name);
  }

  onServiceTypeChange(event) {
    this.selectedServiceType = event;
    this.getDestinations();
    this.getServices();
  }

  onSubmit() {
    const data = {
      destination: this.selectedDestination._id,
      service: this.selectedService._id,
      description: this.descriptionControl.value
    };
    this.destinationServiceService.createDestinationService(data).subscribe(
      (res: any) => {
        this.dialogRef.close(1);
      },
      err => {
        this.dialogRef.close();
      }
    );
  }
}
