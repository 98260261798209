import { Component, OnInit, ViewChild } from "@angular/core";
import { AppAuthService } from "../../auth/auth.service";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../../app.server";
import { AppOrderService } from "../orders.service";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { NgxDrpOptions, PresetItem, Range } from "ngx-mat-daterange-picker";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-order-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"]
})
export class AppOrderListComponent implements OnInit {
  orders: any[] = [];
  isLoading = true;
  private ticketId = null;
  dtOptions: any = {};
  displayedColumns = [
    "index",
    "order",
    "item",
    "service",
    "option",
    "quantity",
    "amount",
    "buyer",
    "phone_number",
    // "address",
    "purchased_date"
  ];

  totalTicket: number;
  totalAmount: number;
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("dateRangePicker") dateRangePicker;

  range: Range = { fromDate: new Date(), toDate: new Date() };
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];


  constructor(
    private orderService: AppOrderService,
    private authService: AppAuthService
  ) { }
  ngOnInit() {
    // this.isLoading = true;
    // this.dtOptions = {
    //   searching: false,
    //   lengthChange: false
    // };
    // this.orderService.getOrders();
    // this.orderService.orderUpdateListener()
    //   .subscribe((displayOrder: any[]) => {
    //     this.orders = [];
    //     this.orders = displayOrder;
    //     this.isLoading = false;
    //   },
    //     (error) => {
    //       this.isLoading = false;
    //       console.log(error);
    //     });
    // this.orderService.getOrders().subscribe(
    //   (data) => {
    //     this.isLoading = false;
    //     this.dataSource.data = data;
    //   },
    //   error => {
    //     this.isLoading = false;
    //     if (
    //       error.error.message === TOKEN_EXPIRED ||
    //       error.error.message === INVALID_TOKEN
    //     ) {
    //       this.authService.needLogin();
    //     }
    //   }
    // );
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sortingDataAccessor = (item, property) => {
    //   switch (property) {
    //     case "buyer": {
    //       return item["buyer"].name;
    //     }
    //     case "ticket": {
    //       return item["ticket"].title;
    //     }
    //     case "contact": {
    //       return item["buyer"].contact_number;
    //     }
    //     case "location": {
    //       return item["buyer"].hotel;
    //     }
    //     default: {
    //       return item[property];
    //     }
    //   }
    // };
    // this.dataSource.sort = this.sort;

    const today = new Date();
    const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const toMax = new Date(today.getFullYear(), today.getMonth() + 2, 0);

    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const resetRange = { fromDate: currMonthStart, toDate: currMonthEnd };
    this.dateRangePicker.resetDates(resetRange);

    this.setupPresets();
    this.options = {
      presets: this.presets,
      format: "mediumDate",
      range: { fromDate: currMonthStart, toDate: currMonthEnd },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false,
        hasBackdrop: false
      },
      cancelLabel: "Cancel",
      excludeWeekends: false,
      fromMinMax: { fromDate: fromMin, toDate: fromMax },
      toMinMax: { fromDate: toMin, toDate: toMax }
    };
  }

  updateRange(range: Range) {
    this.range = range;
    this.reload(this.currentPage);
  }

  // helper function to create initial presets
  setupPresets() {
    const backDate = numOfDays => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      {
        presetLabel: "Yesterday",
        range: { fromDate: yesterday, toDate: today }
      },
      {
        presetLabel: "Last 7 Days",
        range: { fromDate: minus7, toDate: today }
      },
      {
        presetLabel: "Last 30 Days",
        range: { fromDate: minus30, toDate: today }
      },
      {
        presetLabel: "This Month",
        range: { fromDate: currMonthStart, toDate: currMonthEnd }
      },
      {
        presetLabel: "Last Month",
        range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      }
    ];
  }
  onDeleteOrder(id) {
    this.ticketId = id;
  }
  onReallyDeleteOrder() {
    this.orderService.deleteOrder(this.ticketId).subscribe(
      () => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }
  reload(page: any) {
    if (page) {
      this.currentPage = page;
    }
    this.dataSource.data = [];
    this.totalAmount = 0;
    this.totalTicket = 0;
    this.isLoading = true;
    this.orderService
      .getOrders(
        this.range.fromDate.getTime(),
        this.range.toDate.getTime(),
        this.currentPage,
        this.count
      )
      .subscribe(data => {
        console.log("order data:", data);

        this.isLoading = false;
        this.dataSource.data = data;
        data.forEach(element => {
          this.totalTicket = this.totalTicket + element["quantity"];
          this.totalAmount = this.totalAmount + element["total_price"];
        });
      });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      // console.log(property);
      switch (property) {
        case "buyer": {
          return item["buyer"].name;
        }
        case "ticket": {
          return item["ticket"].title;
        }
        case "contact": {
          return item["buyer"].contact_number;
        }
        case "location": {
          return item["buyer"].hotel;
        }
        default: {
          return item[property];
        }
      }
    };
    this.dataSource.sort = this.sort;
  }

  /** Function for pagination */
  onOptionCountChange() {
    // console.log("event");
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
