import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MerchantServiceService } from "../merchant-service.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DestinationService } from "src/app/destination/destination.service";
import { ServiceTypeService } from "src/app/service-type/service-type.service";
@Component({
  selector: "app-edit-merchant-service-dialog",
  templateUrl: "./edit-merchant-service-dialog.component.html",
  styleUrls: ["./edit-merchant-service-dialog.component.css"]
})
export class EditMerchantServiceDialogComponent implements OnInit {
  merchants: any;
  services: any;
  destinations: any;
  serviceTypes: any;
  defaultDestination: any;
  selectedService: any;
  selectedMerchant: any;
  selectedDestination: any;
  selectedServiceType: any;
  constructor(
    public dialogRef: MatDialogRef<EditMerchantServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private merchantServiceService: MerchantServiceService,
    private destinationService: DestinationService,
    private serviceTypeSerive: ServiceTypeService
  ) { }

  merchantServiceFormGroup: FormGroup;
  merchantFormControl = new FormControl("", [Validators.required]);
  serviceFormControl = new FormControl("", [Validators.required]);
  descriptionControl = new FormControl(this.object.description, [
    Validators.required
  ]);
  // originalPriceControl = new FormControl(this.object.original_price, [
  //   Validators.required
  // ]);
  // guidePriceControl = new FormControl(this.object.guide_price, [
  //   Validators.required
  // ]);
  // touristPriceControl = new FormControl(this.object.tourist_price, [
  //   Validators.required
  // ]);
  // commissionControl = new FormControl(this.object.commission, [
  //   Validators.required
  // ]);
  // destinationControl = new FormControl("", [Validators.required]);
  serviceTypeControl = new FormControl("", [Validators.required]);

  ngOnInit() {
    console.log("Merchant Service: ", this.object);
    this.merchantServiceFormGroup = new FormGroup({
      serviceControl: this.serviceFormControl,
      merchantControl: this.merchantFormControl,
      descriptionControl: this.descriptionControl,
      // originalPriceControl: this.originalPriceControl,
      // guidePriceControl: this.guidePriceControl,
      // touristPriceControl: this.touristPriceControl,
      // commissionControl: this.commissionControl,
      // destinationControl: this.destinationControl,
      serviceTypeControl: this.serviceTypeControl
    });
    this.getServiceTypes();
  }

  getServiceTypes() {
    this.serviceTypes = [];
    this.serviceTypeSerive.getServiceTypes().subscribe(
      (res: any) => {
        this.serviceTypes = res;
        this.selectedServiceType = this.object.service_type;
        // this.getDestinations();
        this.getMerchants();
        this.getServices();
      },
      err => {
        console.log(err);
      }
    );
  }

  getMerchants() {
    this.merchants = [];
    this.merchantServiceService
      .getMerchantsByServiceType(this.selectedServiceType._id)
      .subscribe(
        res => {
          this.merchants = res;
          this.selectedMerchant = this.merchants[0];
          this.merchants.forEach(element => {
            if (element._id === this.object.merchant.user_id._id) {
              this.selectedMerchant = element;
            }
          });
          if (this.merchants.length < 1) {
            this.selectedMerchant = null;
            this.merchantFormControl.setValue(null);
          }
        },
        error => {
          console.log(error);
        }
      );
    this.merchantServiceFormGroup.updateValueAndValidity();
  }

  getServices() {
    this.services = [];
    this.merchantServiceService
      .getServicesByType(this.selectedServiceType._id)
      .subscribe(
        res => {
          this.services = res;
          this.selectedService = this.services[0];
          this.services.forEach(element => {
            if (element._id === this.object.service._id) {
              this.selectedService = element;
            }
          });
          if (this.services.length < 1) {
            this.selectedService = null;
            this.serviceFormControl.setValue(null);
          }
        },
        error => {
          console.log(error);
        }
      );
    this.merchantServiceFormGroup.updateValueAndValidity();
  }

  onServiceTypeChange(event) {
    this.selectedServiceType = event;
    // this.getDestinations();
    this.getMerchants();
    this.getServices();
  }

  updateMerchantService() {
    const id = this.object._id;
    const data = {
      merchant: this.selectedMerchant._id,
      service: this.selectedService._id,
      // original_price: this.merchantServiceFormGroup.get("originalPriceControl")
      //   .value,
      // guide_price: this.merchantServiceFormGroup.get("guidePriceControl").value,
      // tourist_price: this.merchantServiceFormGroup.get("touristPriceControl")
      //   .value,
      // commission: this.merchantServiceFormGroup.get("commissionControl").value,
      destination: this.selectedDestination._id,
      service_type: this.selectedServiceType._id
    };
    this.merchantServiceService.updateMerchantService(id, data).subscribe(
      res => {
        this.dialogRef.close(1);
      },
      error => {
        console.log(error);
        this.dialogRef.close(0);
      }
    );
  }

  cancel() {
    this.dialogRef.close(0);
  }

  // getDestinations() {
  //   this.destinations = [];
  //   this.destinationService
  //     .getDestinationsByServiceType(this.selectedServiceType._id)
  //     .subscribe(
  //       res => {
  //         this.destinations = res;
  //         this.selectedDestination = this.destinations[0];
  //         this.destinations.forEach(element => {
  //           if (element._id === this.object.destination._id) {
  //             this.selectedDestination = element;
  //           }
  //         });
  //         if (this.destinations.length < 1) {
  //           this.selectedDestination = null;
  //           this.destinationControl.setValue(null);
  //         }
  //       },
  //       error => {
  //         console.log(error);
  //       }
  //     );
  //   this.merchantServiceFormGroup.updateValueAndValidity();
  // }
}
