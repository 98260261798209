import { Component, OnInit, ViewChild } from "@angular/core";
import { AppAuthService } from "../../auth/auth.service";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../../app.server";
import { AppCurrencyService } from "../currency.service";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";

@Component({
  selector: "app-currency-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"]
})
export class AppCurrencyListComponent implements OnInit {
  currencies: any[] = [];
  isLoading = true;
  private currencyId = null;
  dtOptions: any = {};
  displayedColumns = ["index", "title", "to_riel", "flag", "updated_at", "action"];
  dataSource = new MatTableDataSource();
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private currencyService: AppCurrencyService,
    private authService: AppAuthService
  ) { }
  ngOnInit() {
    this.reload(this.currentPage);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onDeleteCurrency(id) {
    this.currencyId = id;
  }
  onReallyDeleteCurrency() {
    this.currencyService.deleteCurrency(this.currencyId).subscribe(
      () => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  reload(page: number) {
    if (page) {
      this.currentPage = page;
    }
    this.dataSource.data = [];
    this.isLoading = true;
    this.currencyService.getExchangeRatePagination(this.currentPage, this.count).subscribe(
      data => {
        this.isLoading = false;
        this.dataSource.data = data;
      },
      error => {
        this.isLoading = false;
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /** Function for pagination */
  onOptionCountChange() {
    // console.log("event");
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
