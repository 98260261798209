import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog
} from "@angular/material";
import { DestinationService } from "../destination.service";
import { CreateDestinationComponent } from "../create-destination/create-destination.component";
import { EditDestinationComponent } from "../edit-destination/edit-destination.component";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ServiceTypeService } from "src/app/service-type/service-type.service";
@Component({
  selector: "app-destination-list",
  templateUrl: "./destination-list.component.html",
  styleUrls: ["./destination-list.component.css"]
})
export class DestinationListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ["index", "name", "description", "service_type", "action"];
  dataSource = new MatTableDataSource();
  isLoading = true;
  deletedId = "";
  serviceTypes = [];
  selectedType = "";
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 60];
  constructor(
    private destinationService: DestinationService,
    private dialog: MatDialog,
    private serviceTypeService: ServiceTypeService
  ) { }

  paginationFormGroup: FormGroup;
  countFormGroup = new FormControl("", [Validators.required]);
  ngOnInit() {
    this.paginationFormGroup = new FormGroup({
      countControl: this.countFormGroup
    });
    this.countFormGroup.setValue(this.count);
    this.getAllData();
    this.getServiceTypes();
  }

  getServiceTypes() {
    this.serviceTypes = [];
    this.serviceTypeService.getServiceTypes().subscribe(
      (res: any) => {
        this.serviceTypes = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  onTypeChange() {
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  getAllData() {
    this.isLoading = true;
    this.dataSource.data = [];
    this.destinationService
      .getPaginateDestinations(this.selectedType, this.currentPage, this.count)
      .subscribe(
        (res: any) => {
          this.isLoading = false;
          this.dataSource.data = res;
        },
        error => {
          this.isLoading = false;
          console.log("error", error);
        }
      );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openCreateDestinationDialog() {
    const dialogRef = this.dialog.open(CreateDestinationComponent, {
      width: "600px",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "ok") this.getAllData();
    });
  }

  confirmDelete(id: any) {
    this.deletedId = id;
  }

  openEditDestinationDialog(service) {
    const dialogRef = this.dialog.open(EditDestinationComponent, {
      width: "600px",
      disableClose: true,
      data: service
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "ok") this.getAllData();
    });
  }

  onDelete() {
    this.destinationService.deleteDestination(this.deletedId).subscribe(
      res => {
        this.getAllData();
      },
      error => {
        console.log(error);
        this.getAllData();
      }
    );
  }

  reload(page: any) {
    if (page) {
      this.currentPage = page;
    }
    this.getAllData();
  }

  onOptionCountChange() {
    console.log("event");
    console.log(this.count);
    // this.count = event.target.value;
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
