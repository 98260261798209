import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog
} from "@angular/material";
import { DestinationServiceService } from "../destination-service.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CreateDestinationServiceDialogComponent } from "../create-destination-service-dialog/create-destination-service-dialog.component";
import { EditDestinationServiceDialogComponent } from "../edit-destination-service-dialog/edit-destination-service-dialog.component";

@Component({
  selector: "app-destination-service-list",
  templateUrl: "./destination-service-list.component.html",
  styleUrls: ["./destination-service-list.component.css"]
})
export class DestinationServiceListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = [
    "index",
    "service",
    "destination",
    "description",
    "action"
  ];
  dataSource = new MatTableDataSource();
  isLoading = true;
  deletedId = "";
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 60];
  constructor(
    private destinationServiceService: DestinationServiceService,
    private dialog: MatDialog
  ) {}

  paginationFormGroup: FormGroup;
  countFormGroup = new FormControl("", [Validators.required]);
  ngOnInit() {
    this.paginationFormGroup = new FormGroup({
      countControl: this.countFormGroup
    });
    this.countFormGroup.setValue(this.count);
    this.getAllData();
  }

  getAllData() {
    this.isLoading = true;
    this.dataSource.data = [];
    this.destinationServiceService
      .getDestinationServicesWithPagination(this.currentPage, this.count)
      .subscribe(
        (res: any) => {
          console.log("Destination service data: ", res);
          this.isLoading = false;
          this.dataSource.data = res;
        },
        error => {
          this.isLoading = false;
          console.log("error", error);
        }
      );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openCreateDestinationServiceDialog() {
    const dialogRef = this.dialog.open(
      CreateDestinationServiceDialogComponent,
      {
        width: "600px",
        disableClose: true
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.reload(this.currentPage);
      }
    });
  }

  openEditDestinationServiceDialog(destinationService: any) {
    const dialogRef = this.dialog.open(EditDestinationServiceDialogComponent, {
      width: "600px",
      disableClose: true,
      data: destinationService
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.reload(this.currentPage);
      }
    });
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }

  reload(page: any) {
    if (page) {
      this.currentPage = page;
    }
    this.getAllData();
  }

  onOptionCountChange() {
    console.log("event");
    console.log(this.count);
    // this.count = event.target.value;
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  confirmDelete(id: string) {
    this.deletedId = id;
  }

  onDelete() {
    this.destinationServiceService
      .deleteDestinationService(this.deletedId)
      .subscribe(
        (res: any) => {
          this.reload(this.currentPage);
        },
        err => {
          console.log(err);
        }
      );
  }

  activate(id: any) {
    this.destinationServiceService.activate(id).subscribe(
      (res: any) => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
      }
    );
  }

  deactivate(id: any) {
    this.destinationServiceService.deactivate(id).subscribe(
      (res: any) => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
      }
    );
  }
}
