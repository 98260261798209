import { Component, OnInit, ViewChild } from "@angular/core";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";
import { NgForm } from "@angular/forms";
import { MerchantService } from "src/app/merchants/merchant.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppAuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-sub-merchant-edit",
  templateUrl: "./sub-merchant-edit.component.html",
  styleUrls: ["./sub-merchant-edit.component.css"]
})
export class SubMerchantEditComponent implements OnInit {
  @ViewChild("merchantForm") merchantForm: NgForm;

  constructor(
    private merchantService: MerchantService,
    private _route: ActivatedRoute,
    private _router: Router,
    private authService: AppAuthService
  ) {}

  id = "";
  username = "";
  name = "";
  phone_number = "";
  email = "";
  website = "";
  address = "";
  password = "";
  logo: any;
  imgSrc: any;
  merchantId = "";
  parent: any;
  merchant: any;
  selectedImage: File;
  parents: any;
  permissionsChecked: any;
  nothingSelected = false;

  ngOnInit() {
    this.getMerchants();
    this.merchantId = this._route.snapshot.params["id"];
    this.merchantService.getAMerchant(this.merchantId).subscribe(
      (merchant: any) => {
        this.id = merchant._id;
        this.username = merchant.user.username;
        this.name = merchant.user.name;
        this.parent = merchant.parent;
        this.phone_number = merchant.phone_number;
        this.email = merchant.email;
        this.address = merchant.address;
        this.permissionsChecked = merchant.permissions;
        this.website = merchant.website;
        this.imgSrc = merchant.logo;
        this.checkPermission();
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  getFile(event) {
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.imgSrc = reader.result;
      };

      reader.readAsDataURL(file);
    }
  }

  onSubmit() {
    this.getCheckedPermissions();
    const formData = new FormData();
    formData.append("address", this.merchantForm.value.address);
    if (this.selectedImage !== undefined)
      formData.append("logo", this.selectedImage);
    formData.append("email", this.merchantForm.value.email);
    formData.append("username", this.merchantForm.value.username);
    formData.append("name", this.merchantForm.value.name);
    if (this.parent !== null && !undefined)
      formData.append("parent", this.parent);
    formData.append("website", this.merchantForm.value.website);
    for (var i = 0; i < this.permissionsChecked.length; i++) {
      formData.append("permissions", this.permissionsChecked[i]);
    }
    formData.append("phone_number", this.merchantForm.value.phone_number);
    formData.append(
      "password",
      this.merchantForm.value.password == ""
        ? undefined
        : this.merchantForm.value.password
    );
    this.merchantService.updateMerchant(this.id, formData).subscribe(
      () => {
        this._router.navigateByUrl("/sub-merchants");
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  onGoBack() {
    this._router.navigate(["../../"], { relativeTo: this._route });
  }

  getMerchants() {
    this.merchantService.getMerchants().subscribe(
      (merchants: any) => {
        if (merchants.length > 0) {
          this.parents = [];
          this.parents = merchants;
          this.parents.unshift({ name: "This is parent", _id: "0" });
        }
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  checkPermission() {
    let elememts = document.getElementsByClassName("permission");
    for (var i = 0; i < elememts.length; i++) {
      for (var j = 0; j < this.permissionsChecked.length; j++) {
        if (elememts.item(i)["value"] === this.permissionsChecked[j]) {
          elememts.item(i)["checked"] = true;
        }
      }
    }
    this.validateCheckbox();
  }

  validateCheckbox() {
    let checkboxes = document.getElementsByClassName("permission");
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes.item(i)["checked"]) {
        this.nothingSelected = false;
        return;
      } else {
        this.nothingSelected = true;
      }
    }
  }

  getCheckedPermissions() {
    let checkboxes = document.getElementsByClassName("permission");
    this.permissionsChecked = [];
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes.item(i)["checked"]) {
        this.permissionsChecked.push(checkboxes.item(i)["value"]);
      }
    }
  }
}
