import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material";

@Injectable({ providedIn: "root" })
export class LoginGuardService implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | Observable<boolean> | boolean {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    if (token === "" || token === null || role === "" || role == null) {
      return true;
    }

    if (role == "System Admin") {
      this.router.navigateByUrl("/users");
    } else {
      this.showSnackBar("Permission not allow. Please contact administrator");
      return false;
    }
  }

  showSnackBar(message: string) {
    this.snackBar.open(message, null, {
      duration: 2000,
      panelClass: ["red-snackbar"],
      horizontalPosition: "right"
    });
  }
}
