import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Merchant } from "./merchant";
import { MerchantLog } from "./merchantLog";
import { MerchantPayment } from "../merchant-payment/merchant-payment";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class MerchantService {
  BASE_END_POINT = environment.url;
  constructor(private http: HttpClient) { }

  getMerchants(service_type = undefined): Observable<Merchant[]> {
    let url =
      this.BASE_END_POINT +
      "merchants?token=" +
      localStorage.getItem("token") +
      "&parent=null";
    if (service_type) {
      url = url + "&service_type_id=" + service_type;
    }
    return this.http.get<Merchant[]>(url);
  }

  getMerchantByTypeAndPaginate(page: number, count: number, type: any) {
    let query =
      "?parent=null&token=" +
      localStorage.getItem("token") +
      "&count=" +
      count +
      "&page=" +
      page;

    if (type !== "") {
      query = query + "&service_type_id=" + type;
    }

    return this.http.get<Merchant[]>(this.BASE_END_POINT + "merchants" + query);
  }

  getMerchantByType(service_type_id: any) {
    let query = "?parent=null&token=" + localStorage.getItem("token");
    if (service_type_id !== "" && service_type_id !== undefined) {
      query = query + "&service_type_id=" + service_type_id;
    }
    return this.http.get<Merchant[]>(this.BASE_END_POINT + "merchants" + query);
  }

  getAMerchant(id: string) {
    return this.http.get(
      this.BASE_END_POINT +
      "merchants/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }

  addMerchant(formData: FormData) {
    return this.http.post(
      this.BASE_END_POINT + "merchants?token=" + localStorage.getItem("token"),
      formData
    );
  }

  // editMerchant(id: string) {
  //   return this.http.get(
  //     this.BASE_END_POINT +
  //       "merchants/" +
  //       id +
  //       "?token=" +
  //       localStorage.getItem("token")
  //   );
  // }

  updateMerchant(id: string, formData: FormData) {
    return this.http.patch(
      this.BASE_END_POINT +
      "merchants/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      formData
    );
  }

  deleteMerchant(id: string) {
    return this.http.delete(
      this.BASE_END_POINT +
      "merchants/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }

  clearWallet(id: string, wallet: number, note: string) {
    const body = {
      merchant_id: id,
      note: note,
      wallet: wallet
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "merchants/clear_wallet?token=" +
      localStorage.getItem("token"),
      body
    );
  }

  getLogs(merchant_id: string): Observable<MerchantLog[]> {
    return this.http.get<MerchantLog[]>(
      this.BASE_END_POINT +
      "logs/?token=" +
      localStorage.getItem("token") +
      "&merchant_id=" +
      merchant_id
    );
  }

  getMerchantPayments(
    merchant_id: string,
    start_date: string,
    end_date: string,
    page: number,
    count: number
  ): Observable<MerchantPayment[]> {
    return this.http.get<MerchantPayment[]>(
      this.BASE_END_POINT +
      "wallets?token=" +
      localStorage.getItem("token") +
      "&merchant_id=" +
      merchant_id +
      "&start_date=" +
      start_date +
      "&end_date=" +
      end_date +
      "&page=" + page + "&count=" + count
    );
  }

  getSubMerchants(
    parent: string,
    page: number,
    count: number
  ): Observable<Merchant[]> {
    let query = "&count=" + count + "&page=" + page;
    if (parent === "0") {
      console.log("No parameter");
      return this.http.get<Merchant[]>(
        this.BASE_END_POINT +
        "merchants?parent=not_null&token=" +
        localStorage.getItem("token") +
        query
      );
    } else {
      return this.http.get<Merchant[]>(
        this.BASE_END_POINT +
        "merchants?token=" +
        localStorage.getItem("token") +
        "&parent=" +
        parent +
        query
      );
    }
  }

  activateMerchant(id: string) {
    const body = {
      is_active: true
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "merchants/deactivate/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }

  deactivateMerchant(id: string) {
    const body = {
      is_active: false
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "merchants/deactivate/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }
}
