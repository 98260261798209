import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DestinationService } from "../destination.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ServiceTypeService } from "src/app/service-type/service-type.service";

@Component({
  selector: "app-create-destination",
  templateUrl: "./create-destination.component.html",
  styleUrls: ["./create-destination.component.css"]
})
export class CreateDestinationComponent implements OnInit {
  serviceTypes: any;
  selectedServiceType: any;
  constructor(
    public dialogRef: MatDialogRef<CreateDestinationComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private destinationService: DestinationService,
    private serviceTypeService: ServiceTypeService
  ) {}

  destinationFormGroup: FormGroup;
  nameFormControl = new FormControl("", [Validators.required]);
  descriptionControl = new FormControl("", [Validators.required]);
  serviceTypeControl = new FormControl("", [Validators.required]);
  ngOnInit() {
    this.destinationFormGroup = new FormGroup({
      nameControl: this.nameFormControl,
      descriptionControl: this.descriptionControl,
      serviceTypeControl: this.serviceTypeControl
    });
    this.getServiceTypes();
  }

  createDestination() {
    const data = {
      name: this.destinationFormGroup.get("nameControl").value,
      description: this.destinationFormGroup.get("descriptionControl").value,
      service_type: this.selectedServiceType._id
    };
    this.destinationService.createDestination(data).subscribe(
      res => {
        this.dialogRef.close("ok");
      },
      error => {
        console.log(error);
        this.dialogRef.close("cancel");
      }
    );
  }

  cancel() {
    this.dialogRef.close("cancel");
  }

  getServiceTypes() {
    this.serviceTypeService.getServiceTypes().subscribe(
      (res: any) => {
        this.serviceTypes = res;
        this.selectedServiceType = this.serviceTypes[0];
      },
      error => {
        console.log(error);
      }
    );
  }
}
