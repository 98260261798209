import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { SelectionModel } from "@angular/cdk/collections";
import { NgxDrpOptions, PresetItem, Range } from "ngx-mat-daterange-picker";
import { MerchantService } from "src/app/merchants/merchant.service";
import { AppAuthService } from "src/app/auth/auth.service";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";
import { MerchantPaymentService } from "../merchant-payment.service";
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: "app-merchant-payment-list",
  templateUrl: "./merchant-payment-list.component.html",
  styleUrls: ["./merchant-payment-list.component.css"]
})
export class MerchantPaymentListComponent implements OnInit {
  selectedMerchant = { _id: "5be3fbf122b11336188ef456" };
  @ViewChild("dateRangePicker") dateRangePicker;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  merchants = [];
  displayedColumns = [
    "select",
    "index",
    "merchant",
    "total_price",
    "claimed",
    "date",
    "claimer",
    "cid",
    "phone_number"
  ];

  dataSource = new MatTableDataSource();
  range: Range = { fromDate: new Date(), toDate: new Date() };
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  isInvalidToPay = false;
  isLoading = true;
  totalAmount: number;
  note = "";
  name = "";
  cid = "";
  phone_number = "";
  selectedForPrint: any;
  nonCheckAbleRow = 0;
  claimer_name = "";
  claimer_id = "";
  display = "";

  contentForPrint = "";
  merchantName = "";
  currentDate = "";
  totalForPrint = 0;

  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];

  constructor(
    private _merchantService: MerchantService,
    private authService: AppAuthService,
    private _merchantPaymentService: MerchantPaymentService
  ) {
  }

  ngOnInit() {
    const today = new Date();
    const fromMin = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const fromMax = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    );
    const toMin = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const toMax = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    );

    const currMonthStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 8
    );
    const currMonthEnd = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    );

    const resetRange = { fromDate: currMonthStart, toDate: currMonthEnd };
    this.dateRangePicker.resetDates(resetRange);

    this.setupPresets();
    this.options = {
      presets: this.presets,
      format: "mediumDate",
      range: { fromDate: currMonthStart, toDate: currMonthEnd },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false,
        hasBackdrop: false
      },
      cancelLabel: "Cancel",
      excludeWeekends: false,
      fromMinMax: { fromDate: fromMin, toDate: fromMax },
      toMinMax: { fromDate: toMin, toDate: toMax }
    };
    this._merchantService.getMerchants().subscribe(
      data => {
        this.merchants = data;
        this.selectedMerchant = this.merchants[0];
        this.dataSource.data = [];
        this.isLoading = true;
        this.reload(this.currentDate);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }
  changeValue(event) {
    this.selectedMerchant = event;
    this.dataSource.data = [];
    this.selection = new SelectionModel<any>(true, []);
    this.isLoading = true;
    this.reload(this.currentDate);
  }

  // handler function that receives the updated date range object
  updateRange(range: Range) {
    this.range = range;
    // this.dataSource.data = [];
    // this.selection = new SelectionModel<any>(true, []);
    // this.isLoading = true;
    // this._merchantService
    //   .getMerchantPayments(
    //     this.selectedMerchant["_id"],
    //     this.range.fromDate.getTime(),
    //     this.range.toDate.getTime()
    //   )
    //   .subscribe(
    //     data => {
    //       this.isLoading = false;
    //       this.dataSource.data = data;
    //     },
    //     error => {
    //       if (
    //         error.error.message === TOKEN_EXPIRED ||
    //         error.error.message === INVALID_TOKEN
    //       ) {
    //         this.authService.needLogin();
    //       }
    //     }
    //   );
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    this.reload(this.currentDate);
  }

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length - this.nonCheckAbleRow;

    if (numSelected === numRows) {
      return true;
    }
    return false;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => {
        if (row["claimer"] !== null) {
          this.selection.select(row);
        }
      });
  }

  // helper function to create initial presets
  setupPresets() {
    const backDate = numOfDays => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      // {
      //   presetLabel: "Yesterday",
      //   range: { fromDate: yesterday, toDate: today }
      // },
      // {
      //   presetLabel: "Last 7 Days",
      //   range: { fromDate: minus7, toDate: today }
      // },
      // {
      //   presetLabel: "Last 30 Days",
      //   range: { fromDate: minus30, toDate: today }
      // },
      // {
      //   presetLabel: "This Month",
      //   range: { fromDate: currMonthStart, toDate: currMonthEnd }
      // },
      // {
      //   presetLabel: "Last Month",
      //   range: { fromDate: lastMonthStart, toDate: lastMonthEnd }
      // }
    ];
  }

  isNotAvailable() {
    const numSelected = this.selection.selected.length;
    if (numSelected < 1) {
      return true;
    } else {
      for (var i = 1; i < numSelected; i++) {
        const first = this.selection.selected[0].claimer["_id"];
        if (first !== this.selection.selected[i].claimer["_id"]) {
          return true;
        }
      }
    }
  }

  onPaymentClick() {
    const selectedRows = this.selection.selected;
    this.claimer_name = this.selection.selected[0].claimer['full_name'];
    this.claimer_id = this.selection.selected[0].claimer["_id"];
    this.totalAmount = 0;
    for (let row of selectedRows) {
      if (row["is_claim"] == true || row["total_price"] == 0) {
        this.isInvalidToPay = true;
        break;
      }
    }
    if (!this.isInvalidToPay) {
      for (let row of selectedRows) {
        this.totalAmount += row["total_price"];
      }
    }
  }

  onPay() {
    this._merchantPaymentService
      .claimPayment(
        this.selectedMerchant["_id"],
        this.selection.selected,
        this.claimer_name,
        this.claimer_id,
        this.note
      )
      .subscribe(
        data => {
          this.note = '';
          this.openPrintModal();
          this.reload(this.currentDate);
        },
        error => {
          if (
            error.error.message === TOKEN_EXPIRED ||
            error.error.message === INVALID_TOKEN
          ) {
            this.authService.needLogin();
          }
        }
      );
    // this.printClaim();
  }

  onPrint() {
    const WindowPrt = window.open("", "PRINT", "height=700,width=900");

    var header =
      `
    <html><head>
    <title>` +
      document.title +
      `</title>
    <style>
    #print_container {
      width: 80% !important;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5%;
      margin-bottom: 5%;
      transition: 0.3s;
    }
    
    .logo {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      align-content: center;
    }
    
    .logo img {
      width: 40%;
      margin: auto;
      display: block;
    }
    
    .title {
      text-align: center;
    }
    
    .title h3 {
      margin: 10px 0 5px 0;
    }
    
    .summary {
      width: 100%;
    }
    
    .summary .cashier {
      float: left;
    }
    
    .summary .cashier p {
      margin: 0%;
      font-weight: bold;
    }
    
    
    .summary .date {
      float: right;
    }
    
    .summary .date P {
      margin: 0%;
      font-weight: bold;
    }
    
    .clear {
      clear: both;
    }
    
    
    #payment {
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #000;
      margin-top: 5px;
    }
    
    #payment th {
      background: #1960A0;
      color: #ffffff;
      text-align: left;
      padding: 7px;
    }
    
    #payment td {
      border: 1px solid grey;
      padding: 3px;
    }
    
    
    .signature {
      margin-top: 25px;
    }
    
    .signature .cashier-side {
      width: 50%;
      float: left;
      text-align: center;
    }
    
    .signature .merchant-side {
      width: 50%;
      float: right;
      text-align: center
    }
    
    .line {
      width: 60%;
      padding-top: 70px;
      margin: auto;
      border-bottom: 1px solid #000;
    }
    
    .total_price_footer{
        font-weight: bold;
    }
    
    #total_footer{
        text-align: end;
    }
    
    </style>
    `;
    var content = header + document.getElementById("container").innerHTML;

    WindowPrt.document.write(content);
    WindowPrt.document.write("</body></html>");
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

  openPrintModal() {
    this.merchantName = localStorage.getItem("username");
    this.currentDate = new Date().toLocaleDateString();
    this.contentForPrint = "";
    this.totalForPrint = 0;
    this.selectedForPrint = this.selection.selected;
    for (var i = 0; i < this.selectedForPrint.length; i++) {
      this.totalForPrint =
        this.totalForPrint + this.selectedForPrint[i].total_price;
    }
    this.display = "block";
  }

  closePrintModal() {
    this.display = "none";
  }

  reload(page: any) {
    if (page) {
      this.currentPage = page;
    }
    this.dataSource.data = [];
    this.selection = new SelectionModel<any>(true, []);
    this.isLoading = true;
    this.nonCheckAbleRow = 0;
    this._merchantService
      .getMerchantPayments(
        this.selectedMerchant["_id"],
        this.range.fromDate.toISOString(),
        this.range.toDate.toISOString(),
        this.currentPage,
        this.count
      )
      .subscribe(
        data => {
          this.isLoading = false;
          this.dataSource.data = data;
          for (var i = 0; i < data.length; i++) {
            if (data[i]["claimer"] === null) {
              this.nonCheckAbleRow += 1;
            }
          }
        },
        error => {
          this.isLoading = false;
          if (
            error.error.message === TOKEN_EXPIRED ||
            error.error.message === INVALID_TOKEN
          ) {
            this.authService.needLogin();
          }
        }
      );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      // console.log(property);
      switch (property) {
        case "claimer": {
          return item["claimer"].full_name;
        }
        case "cid": {
          return item["claimer"].CID;
        }
        case "phone_number": {
          return item["claimer"].phone_number;
        }
        default: {
          return item[property];
        }
      }
    };
    this.dataSource.sort = this.sort;
  }

  isNullOrNotSelectable() {
    if (
      this.dataSource.data.length === 0 ||
      this.dataSource.data.length === this.nonCheckAbleRow
    ) {
      return true;
    } else {
      return false;
    }
  }

  printClaim() {
    const WindowPrt = window.open("", "PRINT", "height=700,width=900");

    var header =
      `
<html><head>
    <title>` +
      document.title +
      `</title>
    `;
    var content = document.getElementById("container");
    WindowPrt.document.write();
    WindowPrt.document.write("</body></html>");
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
  /** Function for pagination */
  onOptionCountChange() {
    // console.log("event");
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
