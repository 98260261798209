import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OptionService } from 'src/app/option/option.service';
import { MerchantService } from 'src/app/merchants/merchant.service';
import { MerchantOptionService } from '../merchant-option.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-merchant-option',
  templateUrl: './edit-merchant-option.component.html',
  styleUrls: ['./edit-merchant-option.component.css']
})
export class EditMerchantOptionComponent implements OnInit {
  options: any;
  selectedOption: any;
  merchants: any;
  selectedMerchant: any;
  constructor(
    public dialogRef: MatDialogRef<EditMerchantOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private optionService: OptionService,
    private merchantService: MerchantService,
    private merchantOptionService: MerchantOptionService
  ) { }

  merchantOptionFormGroup: FormGroup;
  quantityControl = new FormControl(this.object.quantity, [
    Validators.required,
    Validators.min(0)
  ]);
  merchantControl = new FormControl("", [Validators.required]);
  optionControl = new FormControl("", [Validators.required]);

  ngOnInit() {
    console.log("object: ", this.object)
    this.merchantOptionFormGroup = new FormGroup({
      optionControl: this.optionControl,
      merchantControl: this.merchantControl,
      quantityControl: this.quantityControl
    })
    this.getOption();
    this.getMerchant();
  }

  getOption() {
    this.options = [];
    this.optionService.getAllOptions().subscribe(
      (res: any) => {
        this.options = res;
        console.log("merchant: ", this.options[0])
        this.selectedOption = this.object.option;
      },
      err => {
        console.log(err);
      }
    );
  }

  getMerchant() {
    this.merchants = [];
    this.merchantService.getMerchants().subscribe(
      (res: any) => {
        this.merchants = res;
        this.selectedMerchant = this.object.merchant;

      },
      err => {
        console.log(err);
      }
    );
  }

  updateMerchantOption() {
    const id = this.object._id;
    const data = {
      option: this.selectedOption._id,
      merchant: this.selectedMerchant._id,
      quantity: this.quantityControl.value
    }
    this.merchantOptionService.updateMerchantOption(id, data).subscribe(
      res => {
        this.dialogRef.close(1);
      },
      err => {
        this.dialogRef.close(0);
      }
    );
  }

  cancel() {
    this.dialogRef.close(0);
  }
}
