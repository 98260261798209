import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator, MatSort } from "@angular/material";
import { UserService } from "../user.service";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";
import { AppAuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"]
})
export class UserListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _userService: UserService,
    private authService: AppAuthService
  ) { }

  deleteId = "";
  isLoading = true;
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];

  displayedColumns = ["index", "username", "name", "role.name", "action"];
  dataSource = new MatTableDataSource();

  ngOnInit() {
    this.reload(this.currentPage);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      // console.log(property);
      switch (property) {
        case "role.name": {
          return item["role"].name;
        }
        default: {
          return item[property];
        }
      }
    };
    this.dataSource.sort = this.sort;
  }

  confirmDelete(id: string) {
    this.deleteId = id;
  }

  onDelete() {
    this._userService.deleteUser(this.deleteId).subscribe(
      () => {
        this.dataSource.data = [];
        this.isLoading = true;
        this._userService.getUsers().subscribe(
          data => {
            this.isLoading = false;
            this.dataSource.data = data;
          },
          error => {
            this.isLoading = false;
            if (
              error.error.message === TOKEN_EXPIRED ||
              error.error.message === INVALID_TOKEN
            ) {
              this.authService.needLogin();
            }
          }
        );
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  reload(page: number) {
    if (page) {
      this.currentPage = page;
    }
    this.dataSource.data = [];
    this.isLoading = true;
    this._userService.getUsersPagination(this.currentPage, this.count).subscribe(
      data => {
        this.isLoading = false;
        this.dataSource.data = data;
      },
      error => {
        this.isLoading = false;
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  /** Function for pagination */
  onOptionCountChange() {
    // console.log("event");
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
