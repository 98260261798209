import { Component } from "@angular/core";

@Component({
  selector: 'app-currencies',
  templateUrl: './currencies.component.html',
  styleUrls: ['./currencies.component.css']
})
export class AppCurrenciesComponent {

}
