import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog
} from "@angular/material";
import { OptionService } from "../option.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CreateOptionDialogComponent } from "../create-option-dialog/create-option-dialog.component";
import { EditOptionDialogComponent } from "../edit-option-dialog/edit-option-dialog.component";

@Component({
  selector: "app-option-list",
  templateUrl: "./option-list.component.html",
  styleUrls: ["./option-list.component.css"]
})
export class OptionListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = [
    "index",
    "name",
    // "quantity",
    "service",
    "destination",
    "original_price",
    "tourist_price",
    "guide_price",
    "commission",
    "description",
    // "merchant",
    "action"
  ];
  dataSource = new MatTableDataSource();
  isLoading = true;
  deletedId = "";
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 60];

  constructor(
    private optionService: OptionService,
    private dialog: MatDialog
  ) { }

  paginationFormGroup: FormGroup;
  countFormGroup = new FormControl("", [Validators.required]);
  ngOnInit() {
    this.paginationFormGroup = new FormGroup({
      countControl: this.countFormGroup
    });
    this.countFormGroup.setValue(this.count);
    this.getAllData();
  }

  getAllData() {
    this.isLoading = true;
    this.dataSource.data = [];
    this.optionService
      .getOptionsWithPagination(this.currentPage, this.count)
      .subscribe(
        (res: any) => {
          console.log("Option data: ", res);
          this.isLoading = false;
          this.dataSource.data = res;
        },
        error => {
          this.isLoading = false;
          console.log("error", error);
        }
      );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  confirmDelete(id: any) {
    this.deletedId = id;
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }

  onOptionCountChange() {
    console.log("event");
    console.log(this.count);
    // this.count = event.target.value;
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  reload(page: any) {
    if (page) {
      this.currentPage = page;
    }
    this.getAllData();
  }

  openCreateOptionDialog() {
    const dialogRef = this.dialog.open(CreateOptionDialogComponent, {
      width: "800px",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reload(this.currentPage);
      }
    });
  }

  openEditOptionDialog(option: any) {
    const dialogRef = this.dialog.open(EditOptionDialogComponent, {
      width: "800px",
      disableClose: true,
      data: option
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reload(this.currentPage);
      }
    });
  }

  onDelete() {
    this.optionService.deleteOption(this.deletedId).subscribe(
      res => {
        this.reload(this.currentPage);
      },
      err => {
        console.log(err);
      }
    );
  }

  activate(id: any) {
    this.optionService.activate(id).subscribe(
      (res: any) => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
      }
    );
  }

  deactivate(id: any) {
    this.optionService.deactivate(id).subscribe(
      (res: any) => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
      }
    );
  }
}
