import { Component, OnInit, ViewChild } from "@angular/core";
import { MerchantService } from "src/app/merchants/merchant.service";
import { AppAuthService } from "src/app/auth/auth.service";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";
import { ServiceTypeService } from "src/app/service-type/service-type.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-sub-merchant-list",
  templateUrl: "./sub-merchant-list.component.html",
  styleUrls: ["./sub-merchant-list.component.css"]
})
export class SubMerchantListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    "index",
    "username",
    "name",
    "phone_number",
    "email",
    // "website",
    // "wallet",
    // "address",
    "action"
  ];

  dataSource = new MatTableDataSource();
  isLoading = true;
  deleteId = "";
  parents = [];

  types = [];
  selectedType = "";
  selectedParent: any;
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];

  constructor(
    private _merchantService: MerchantService,
    private authService: AppAuthService,
    private serviceTypeService: ServiceTypeService
  ) { }

  ngOnInit() {
    this.getParents();
    this.getTypes();

    this.selectedParent = "0";
    this.reload(this.currentPage);
  }

  confirmDelete(id: string) {
    this.deleteId = id;
  }

  onFilter() {
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onDelete() {
    this._merchantService.deleteMerchant(this.deleteId).subscribe(
      () => {
        this.dataSource.data = [];
        this.isLoading = true;
        // this._merchantService.getMerchants().subscribe(
        //   data => {
        //     this.isLoading = false;
        //     this.dataSource.data = data;
        //   },
        //   error => {
        //     console.log(error);
        //     this.isLoading = false;
        //     if (
        //       error.error.message === TOKEN_EXPIRED ||
        //       error.error.message === INVALID_TOKEN
        //     ) {
        //       this.authService.needLogin();
        //     }
        //   }
        // );
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  reload(page: any) {
    if (page) {
      this.currentPage = page;
    }
    this.dataSource.data = [];
    this.isLoading = true;
    this._merchantService
      .getSubMerchants(this.selectedParent, this.currentPage, this.count)
      .subscribe(
        data => {
          this.isLoading = false;
          this.dataSource.data = data;
        },
        error => {
          console.log(error);
          this.isLoading = false;
          if (
            error.error.message === TOKEN_EXPIRED ||
            error.error.message === INVALID_TOKEN
          ) {
            this.authService.needLogin();
          }
        }
      );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getParents() {
    this._merchantService.getMerchants().subscribe((parents: any) => {
      this.parents = [];
      this.parents = parents;
      console.log("merchant: ", this.parents);
    });
  }

  getTypes() {
    this.types = [];
    this.serviceTypeService.getServiceTypes().subscribe(
      (res: any) => {
        this.types = res;
      },
      err => { }
    );
  }
  onOptionCountChange() {
    // console.log("event");
    // console.log(event.target.value, this.currentPage);
    // this.count = event.target.value;
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
