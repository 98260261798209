import { Component, OnInit, ViewChild } from "@angular/core";
import { MerchantService } from "../merchant.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";
import { AppAuthService } from "src/app/auth/auth.service";
import { ServiceTypeService } from "src/app/service-type/service-type.service";
import { LanguageService } from "src/app/language/language.service";

@Component({
  selector: "app-merchant-edit",
  templateUrl: "./merchant-edit.component.html",
  styleUrls: ["./merchant-edit.component.css"]
})
export class MerchantEditComponent implements OnInit {
  @ViewChild("merchantForm") merchantForm: NgForm;

  constructor(
    private _merchantService: MerchantService,
    private _route: ActivatedRoute,
    private _router: Router,
    private authService: AppAuthService,
    private serviceTypeService: ServiceTypeService,
    // private languageService: LanguageService
  ) { }

  id = "";
  username = "";
  name = "";
  phone_number = "";
  email = "";
  website = "";
  address = "";
  password = "";
  logo: any;
  types = ["commission_base", "guarantee_base"];
  type = "";
  serviceType = "";
  // language: "";
  imgSrc: any;
  merchantId = "";
  merchant: any;
  selectedImage: File;
  nothingSelected = false;
  permissionsChecked: any;
  serviceTypes: any;
  languages: any;

  ngOnInit() {
    this.getServiceTypes();
    // this.getLanguages();
    this.merchantId = this._route.snapshot.params["id"];
    this._merchantService.getAMerchant(this.merchantId).subscribe(
      (merchant: any) => {
        this.id = merchant._id;
        this.username = merchant.user.username;
        this.name = merchant.user.name;
        this.serviceType = merchant.service_type ? merchant.service_type._id : "";
        this.type = merchant.type;
        // this.language = merchant.language;
        this.phone_number = merchant.phone_number;
        this.email = merchant.email;
        this.address = merchant.address;
        this.permissionsChecked = merchant.permissions;
        this.website = merchant.website;
        this.imgSrc = merchant.logo;
        // this.checkPermission();
        console.log(merchant);
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  getFile(event) {
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.imgSrc = reader.result;
      };

      reader.readAsDataURL(file);
    }
  }

  onSubmit() {
    // this.getCheckedPermissions();
    const formData = new FormData();
    formData.append("address", this.merchantForm.value.address);
    formData.append("logo", this.selectedImage);
    formData.append("email", this.merchantForm.value.email);
    formData.append("username", this.merchantForm.value.username);
    formData.append("name", this.merchantForm.value.name);
    formData.append("type", this.merchantForm.value.type);
    formData.append("service_type_id", this.merchantForm.value.service_type);
    formData.append("language", this.merchantForm.value.language);
    formData.append("website", this.merchantForm.value.website);
    formData.append("phone_number", this.merchantForm.value.phone_number);
    for (var i = 0; i < this.permissionsChecked.length; i++) {
      formData.append("permissions", this.permissionsChecked[i]);
    }
    if (this.merchantForm.value.password !== "") {
      formData.append("password", this.merchantForm.value.password);
    }
    this._merchantService.updateMerchant(this.id, formData).subscribe(
      () => {
        this._router.navigateByUrl("/merchants");
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  onGoBack() {
    this._router.navigate(["../../"], { relativeTo: this._route });
  }

  checkPermission() {
    let elements = document.getElementsByClassName("permission");
    for (var i = 0; i < elements.length; i++) {
      for (var j = 0; j < this.permissionsChecked.length; j++) {
        if (elements.item(i)["value"] === this.permissionsChecked[j]) {
          elements.item(i)["checked"] = true;
        }
      }
    }
    this.validateCheckbox();
  }

  validateCheckbox() {
    let checkBoxes = document.getElementsByClassName("permission");
    for (var i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes.item(i)["checked"]) {
        this.nothingSelected = false;
        return;
      } else {
        this.nothingSelected = true;
      }
    }
  }

  getCheckedPermissions() {
    let checkBoxes = document.getElementsByClassName("permission");
    this.permissionsChecked = [];
    for (var i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes.item(i)["checked"]) {
        this.permissionsChecked.push(checkBoxes.item(i)["value"]);
      }
    }
  }

  getServiceTypes() {
    this.serviceTypeService.getServiceTypes().subscribe(
      res => {
        console.log("service type: ", res);
        this.serviceTypes = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  // getLanguages() {
  //   this.languageService.getLanguages().subscribe(
  //     res => {
  //       console.log("language: ", res);
  //       this.languages = res;
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }
}
