import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Transaction } from './transaction';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TransationService {

  BASE_END_POINT = environment.url;
  constructor(private _http: HttpClient) { }

  getTrantransactions(merchant_id: string, start_date: number, end_date: number): Observable<Transaction[]>{
    let query = 'token=' + localStorage.getItem('token') ;
    if (merchant_id != null){
      query = query + "&merchant_id="+ merchant_id ;
    }
    if (start_date != null){
      query = query + "&start_date="+ start_date;
    }
    if (end_date != null){
      query = query + "&end_date="+ end_date ;
    }
    return this._http.get<Transaction[]>(this.BASE_END_POINT + 'orders?' + query);
  }
  pay(selectedTransaction: any){
    const transation = {
      transactions: selectedTransaction
    }
    return this._http.post(this.BASE_END_POINT+'payments/clear_transaction?token='+ localStorage.getItem('token'), transation);
  }
}
