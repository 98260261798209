import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class SaleShiftService {
  BASE_END_POINT = environment.url;

  constructor(private http: HttpClient) { }

  getSaleShifts(page: number, count: number) {
    // console.log("get sale shift")
    let query = "&count=" + count + "&page=" + page;
    return this.http.get(
      this.BASE_END_POINT + "sale_shifts?token=" + localStorage.getItem("token") + query
    );
  }

  clear(id: any) {
    const body = {
      is_clear: true
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "sale_shifts/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }

  unclear(id: any) {
    const body = {
      is_clear: false
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "sale_shifts/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }
}
