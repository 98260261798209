import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AppAuthService {
  private isAuthenticated = false;
  private token;
  BASE_END_POINT = environment.url;

  constructor(private http: HttpClient, private router: Router) {}
  setToken(token: string) {
    this.token = token;
  }
  needLogin() {
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
  getToken() {
    return this.token;
  }
  setAuth(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }
  getIsAuth() {
    return this.isAuthenticated;
  }
  getRole() {
    return localStorage.getItem('role');
  }
  login(username: string, password: string) {
    return this.http.post(this.BASE_END_POINT + 'auth', { username: username, password: password});
  }
  logout() {
    this.token = null;
    this.isAuthenticated = false;
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('logo_link');
    localStorage.removeItem('role');
    localStorage.removeItem('userId');
    localStorage.removeItem('expiresAt');
    this.router.navigate(['/login']);
  }
  saveAuthData(username: string, token: string, logo_link: string, role: string, userId: string, expiresAt: number) {
    localStorage.setItem('username', username);
    localStorage.setItem('token', token);
    localStorage.setItem('logo_link', logo_link);
    localStorage.setItem('role', role);
    localStorage.setItem('userId', userId);
    localStorage.setItem('expiresAt', expiresAt.toString());

  }
}
