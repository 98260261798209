import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ServiceDetailServiceService } from "../service-detail-service.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-create-service-detail",
  templateUrl: "./create-service-detail.component.html",
  styleUrls: ["./create-service-detail.component.css"]
})
export class CreateServiceDetailComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateServiceDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private serviceDetailService: ServiceDetailServiceService
  ) {}
  serviceDetailFormGroup: FormGroup;
  nameFormControl = new FormControl("", [Validators.required]);
  descriptionControl = new FormControl("", [Validators.required]);

  ngOnInit() {
    this.serviceDetailFormGroup = new FormGroup({
      nameControl: this.nameFormControl,
      descriptionControl: this.descriptionControl
    });
  }

  createServiceDetail() {
    const data = {
      name: this.serviceDetailFormGroup.get("nameControl").value,
      description: this.serviceDetailFormGroup.get("descriptionControl").value
    };
    this.serviceDetailService.createServiceDetail(data).subscribe(
      res => {
        this.dialogRef.close("ok");
      },
      error => {
        console.log(error);
        this.dialogRef.close("cancel");
      }
    );
  }

  cancel() {
    this.dialogRef.close("cancel");
  }
}
