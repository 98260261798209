import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class MerchantPaymentService {
  constructor(private _http: HttpClient) {}
  BASE_END_POINT = environment.url;
  claimPayment(merchant_id: string, data: any[], claimer_name: string = null, claimer_id: string = null, note: string = null) {
    const body = {
      merchant_id: merchant_id,
      admin_id: localStorage.getItem('userId'),
      data: data,
      claimer_name: claimer_name,
      claimer_id: claimer_id,
      note: note,

    };
    return this._http.patch(
      this.BASE_END_POINT +
        "claim/updateWallet?token=" +
        localStorage.getItem("token"),
      body
    );
  }
}
