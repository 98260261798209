import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { OptionService } from "../option.service";
import { DestinationService } from "src/app/destination/destination.service";
import { AppTicketService } from "src/app/services/services.service";
import { MerchantService } from "src/app/merchants/merchant.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-edit-option-dialog",
  templateUrl: "./edit-option-dialog.component.html",
  styleUrls: ["./edit-option-dialog.component.css"]
})
export class EditOptionDialogComponent implements OnInit {
  destinations: any;
  selectedDestination: any;
  services: any;
  selectedService: any;
  merchants: any;
  selectedMerchant: any;
  constructor(
    public dialogRef: MatDialogRef<EditOptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private optionService: OptionService,
    private destinationService: DestinationService,
    private serviceService: AppTicketService,
    private merchantService: MerchantService
  ) { }

  optionFormGroup: FormGroup;
  nameControl = new FormControl(this.object.name, [Validators.required]);
  // quantityControl = new FormControl(this.object.quantity, [
  //   Validators.required,
  //   Validators.min(0)
  // ]);
  descriptionControl = new FormControl(this.object.description);
  originalPriceControl = new FormControl(this.object.original_price, [
    Validators.required,
    Validators.min(0)
  ]);
  touristPriceControl = new FormControl(this.object.tourist_price, [
    Validators.required,
    Validators.min(0)
  ]);
  guidePriceControl = new FormControl(this.object.guide_price, [
    Validators.required,
    Validators.min(0)
  ]);
  commissionControl = new FormControl(this.object.commission, [
    Validators.required
  ]);
  destinationControl = new FormControl("", [Validators.required]);
  serviceControl = new FormControl("", [Validators.required]);
  // merchantControl = new FormControl("", [Validators.required]);
  ngOnInit() {
    console.log("edit data: ", this.object);
    this.optionFormGroup = new FormGroup({
      nameControl: this.nameControl,
      // quantityControl: this.quantityControl,
      descriptionControl: this.descriptionControl,
      originalPriceControl: this.originalPriceControl,
      touristPriceControl: this.originalPriceControl,
      guidePriceControl: this.guidePriceControl,
      commissionControl: this.commissionControl,
      destinationControl: this.destinationControl,
      serviceControl: this.serviceControl,
      // merchantControl: this.merchantControl
    });
    this.getDestinations();
    this.getServices();
    this.onFormGroupChange();
  }

  onFormGroupChange() {
    this.nameControl.valueChanges.subscribe(val => {
      this.descriptionControl.setValue(this.selectedService.title + " to " + this.selectedDestination.name + " with " + this.nameControl.value);
    });
    this.originalPriceControl.valueChanges.subscribe(val => {
      this.touristPriceControl.setValue(Number(this.originalPriceControl.value) + Number(this.commissionControl.value));
      this.guidePriceControl.setValue(Number(this.originalPriceControl.value) + Number(this.commissionControl.value));
    });
    this.commissionControl.valueChanges.subscribe(val => {
      this.touristPriceControl.setValue(Number(this.originalPriceControl.value) + Number(this.commissionControl.value));
      this.guidePriceControl.setValue(Number(this.originalPriceControl.value) + Number(this.commissionControl.value));
    });
  }

  updateOption() {
    const id = this.object._id;
    const data = {
      name: this.nameControl.value,
      // quantity: this.quantityControl.value,
      description: this.descriptionControl.value,
      original_price: this.originalPriceControl.value,
      tourist_price: this.touristPriceControl.value,
      guide_price: this.guidePriceControl.value,
      commission: this.commissionControl.value,
      destination: this.selectedDestination._id,
      service: this.selectedService._id,
      // merchant: this.selectedMerchant._id
    };
    this.optionService.updateOption(id, data).subscribe(
      res => {
        this.dialogRef.close(1);
      },
      err => {
        this.dialogRef.close(0);
      }
    );
  }

  cancel() {
    this.dialogRef.close(0);
  }

  getDestinations() {
    this.destinations = [];
    this.destinationService.getDestinations().subscribe(
      (res: any) => {
        this.destinations = res;
        this.selectedDestination = this.object.destination;
      },
      err => {
        console.log(err);
      }
    );
  }

  getServices() {
    this.services = [];
    this.serviceService.getAllServices().subscribe(
      (res: any) => {
        this.services = res;
        this.selectedService = this.object.service;
      },
      err => {
        console.log(err);
      }
    );
  }

  getMerchants() {
    this.merchants = [];
    this.merchantService.getMerchants().subscribe(
      (res: any) => {
        this.merchants = res;
        this.selectedMerchant = this.object.merchant;
      },
      err => {
        console.log(err);
      }
    );
  }

  onServiceChange(event) {
    this.descriptionControl.setValue(this.selectedService.title + " to " + this.selectedDestination.name + " with " + this.nameControl.value);
  }

  onDestinationChange(event) {
    this.descriptionControl.setValue(this.selectedService.title + " to " + this.selectedDestination.name + " with " + this.nameControl.value);
  }
}
