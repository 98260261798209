import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class LanguageService {
  BASE_END_POINT = environment.url;

  constructor(private http: HttpClient) { }

  getLanguages() {
    return this.http.get(
      this.BASE_END_POINT +
      "languages?token=" +
      localStorage.getItem("token")
    );
  }

  getLanguagesPagination(page: number, count: number) {
    let query = "&page=" + page + "&count=" + count;
    return this.http.get(
      this.BASE_END_POINT +
      "languages?token=" +
      localStorage.getItem("token") + query
    );
  }

  createLanguage(data: any) {
    return this.http.post(
      this.BASE_END_POINT +
      "languages?token=" +
      localStorage.getItem("token"),
      data
    );
  }

  updateLanguage(id: any, data: any) {
    return this.http.patch(
      this.BASE_END_POINT +
      "languages/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      data
    );
  }

  deleteLanguage(id: any) {
    return this.http.delete(
      this.BASE_END_POINT +
      "languages/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }
}
