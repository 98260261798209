import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatDialog,
  MatTableDataSource
} from "@angular/material";
import { ServiceTypeService } from "../service-type.service";
import { CreateServiceTypeDialogComponent } from "../create-service-type-dialog/create-service-type-dialog.component";
import { EditServiceTypeDialogComponent } from "../edit-service-type-dialog/edit-service-type-dialog.component";

@Component({
  selector: "app-service-type-list",
  templateUrl: "./service-type-list.component.html",
  styleUrls: ["./service-type-list.component.css"]
})
export class ServiceTypeListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ["index", "name", "description", "action"];
  dataSource = new MatTableDataSource();
  isLoading = true;
  deletedId = "";
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];

  constructor(
    private serviceTypeService: ServiceTypeService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.reload(this.currentPage);
  }


  getAllData() {
    this.isLoading = true;
    this.dataSource.data = [];
    this.serviceTypeService.getServiceTypesPagination(this.currentPage, this.count).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.dataSource.data = res;
      },
      error => {
        this.isLoading = false;
        console.log("error", error);
      }
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openCreateServiceTypeDialog() {
    const dialogRef = this.dialog.open(CreateServiceTypeDialogComponent, {
      width: "600px",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "ok") this.getAllData();
    });
  }

  confirmDelete(id: any) {
    this.deletedId = id;
  }

  openEditServiceTypeDialog(service) {
    const dialogRef = this.dialog.open(EditServiceTypeDialogComponent, {
      width: "600px",
      disableClose: true,
      data: service
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == "ok") this.getAllData();
    });
  }

  onDelete() {
    this.serviceTypeService.deleteServiceType(this.deletedId).subscribe(
      res => {
        this.getAllData();
      },
      error => {
        console.log(error);
        this.getAllData();
      }
    );
  }

  reload(page: number) {
    if (page) {
      this.currentPage = page;
    }
    this.getAllData();
  }

  /** Function for pagination */
  onOptionCountChange() {
    // console.log("event");
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
