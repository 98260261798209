import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog
} from "@angular/material";
import { MerchantServiceService } from "../merchant-service.service";
import { CreateMerchantServiceDialogComponent } from "../create-merchant-service-dialog/create-merchant-service-dialog.component";
import { EditMerchantServiceDialogComponent } from "../edit-merchant-service-dialog/edit-merchant-service-dialog.component";
import { ServiceTypeService } from "src/app/service-type/service-type.service";
import { MerchantService } from "src/app/merchants/merchant.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-merchant-service-list",
  templateUrl: "./merchant-service-list.component.html",
  styleUrls: ["./merchant-service-list.component.css"]
})
export class MerchantServiceListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    "index",
    "service",
    "merchant",
    // "destination",
    // "description",
    "service_type",
    // "original_price",
    // "guide_price",
    // "tourist_price",
    // "commission",
    "status",
    "action"
  ];
  dataSource = new MatTableDataSource();
  isLoading = true;
  deletedId = "";
  serviceTypes = [];
  selectedType = "";
  merchants = [];
  selectedMerchant = "";
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];

  paginationFormGroup: FormGroup;
  countFormGroup = new FormControl("", [Validators.required]);

  constructor(
    private merchantService: MerchantServiceService,
    private dialog: MatDialog,
    private serviceTypeService: ServiceTypeService,
    private merchant: MerchantService
  ) { }

  ngOnInit() {
    this.paginationFormGroup = new FormGroup({
      countControl: this.countFormGroup
    });
    this.countFormGroup.setValue(this.count);
    this.reload(this.currentPage);
    this.getServiceTypes();
    this.getMerchants();
  }

  getAllData(type: any, merchant: any) {
    this.dataSource.data = [];
    this.isLoading = true;
    this.merchantService
      .getAllMerchantServices(type, merchant, this.currentPage, this.count)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.isLoading = false;
          this.dataSource.data = res;
        },
        error => {
          this.isLoading = false;
          console.log("error", error);
        }
      );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openCreateMerchantServiceDialog() {
    const dialogRef = this.dialog.open(CreateMerchantServiceDialogComponent, {
      width: "600px",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("success create merchant service");
      if (result === 1) {
        this.reload(this.currentPage);
      }
    });
  }

  confirmDelete(id: any) {
    this.deletedId = id;
  }

  openEditMerchantServiceDialog(service) {
    const dialogRef = this.dialog.open(EditMerchantServiceDialogComponent, {
      width: "600px",
      disableClose: true,
      data: service
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        this.reload(this.currentPage);
      }
    });
  }

  onDelete() {
    this.merchantService.deleteMerchantService(this.deletedId).subscribe(
      res => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
        this.reload(this.currentPage);
      }
    );
  }

  activate(id: any) {
    this.merchantService.activate(id).subscribe(
      (res: any) => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
      }
    );
  }

  deactivate(id: any) {
    this.merchantService.deactivate(id).subscribe(
      (res: any) => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
      }
    );
  }

  reload(page: any) {
    if (page) {
      this.currentPage = page;
    }
    this.getAllData(this.selectedType, this.selectedMerchant);
  }

  getServiceTypes() {
    this.serviceTypes = [];
    this.serviceTypeService.getServiceTypes().subscribe(
      (res: any) => {
        this.serviceTypes = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  getMerchants() {
    this.merchant.getMerchants().subscribe(
      (res: any) => {
        this.merchants = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  onMerchantChange() {
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onTypeChange() {
    this.currentPage = 1;
    this.reload(this.currentPage);
  }
  /** Function for pagination */
  onOptionCountChange() {
    // console.log("event");
    // console.log(event.target.value, this.currentPage);
    // this.count = event.target.value;
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
