import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ServiceTypeService } from "../service-type.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-create-service-type-dialog",
  templateUrl: "./create-service-type-dialog.component.html",
  styleUrls: ["./create-service-type-dialog.component.css"]
})
export class CreateServiceTypeDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateServiceTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private serviceTypeService: ServiceTypeService
  ) {}

  serviceTypeFormGroup: FormGroup;
  nameFormControl = new FormControl("", [Validators.required]);
  descriptionControl = new FormControl("", [Validators.required]);

  ngOnInit() {
    this.serviceTypeFormGroup = new FormGroup({
      nameControl: this.nameFormControl,
      descriptionControl: this.descriptionControl
    });
  }

  createServiceType() {
    const data = {
      name: this.serviceTypeFormGroup.get("nameControl").value,
      description: this.serviceTypeFormGroup.get("descriptionControl").value
    };
    this.serviceTypeService.createServiceType(data).subscribe(
      res => {
        this.dialogRef.close("ok");
      },
      error => {
        console.log(error);
        this.dialogRef.close("cancel");
      }
    );
  }

  cancel() {
    this.dialogRef.close("cancel");
  }
}
