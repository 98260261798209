import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pos } from './pos';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PosService {

  BASE_END_POINT = environment.url;
  constructor(private http: HttpClient) { }
  getPos(): Observable<Pos[]> {
    return this.http.get<Pos[]>(this.BASE_END_POINT + 'pos?token=' + localStorage.getItem('token'))
  }
  getPosPagination(page: number, count: number): Observable<Pos[]> {
    let query = "&count=" + count + "&page=" + page;
    return this.http.get<Pos[]>(this.BASE_END_POINT + 'pos?token=' + localStorage.getItem('token') + query)
  }
  addPos(pos: any) {
    return this.http.post(this.BASE_END_POINT + 'pos?token=' + localStorage.getItem('token'), pos);
  }

  editPos(id: string) {
    return this.http.get(this.BASE_END_POINT + 'pos/' + id + '?token=' + localStorage.getItem('token'));
  }

  updatePos(id: string, pos: any) {
    return this.http.patch(this.BASE_END_POINT + 'pos/' + id + '?token=' + localStorage.getItem('token'), pos)
  }

  deletePos(id: string) {
    return this.http.delete(this.BASE_END_POINT + 'pos/' + id + '?token=' + localStorage.getItem('token'));
  }

}