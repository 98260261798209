import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { MerchantOptionService } from '../merchant-option.service';
import { EditMerchantOptionComponent } from '../edit-merchant-option/edit-merchant-option.component';
import { CreateMerchantOptionComponent } from '../create-merchant-option/create-merchant-option.component';

@Component({
  selector: 'app-merchant-option-list',
  templateUrl: './merchant-option-list.component.html',
  styleUrls: ['./merchant-option-list.component.css']
})
export class MerchantOptionListComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ["index", "option", "merchant", "quantity", "action"];
  dataSource = new MatTableDataSource();
  isLoading = true;
  deletedId = "";
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];
  constructor(
    private merchantOptionService: MerchantOptionService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.reload(this.currentPage);
  }

  reload(page: number) {
    if (page) {
      this.currentPage = page;
    }
    this.isLoading = true;
    this.dataSource.data = [];
    this.merchantOptionService.getMerchantOptionsPagination(this.currentPage, this.count).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.dataSource.data = res;
      },
      error => {
        this.isLoading = false;
        console.log("error", error);
      }
    );
    this.dataSource.sort = this.sort;
  }

  openCreateMerchantOptionDialog() {
    const dialogRef = this.dialog.open(CreateMerchantOptionComponent, {
      width: "500px",
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reload(this.currentPage);
      }
    });
  }

  openEditMerchantOptionDialog(service) {
    const dialogRef = this.dialog.open(EditMerchantOptionComponent, {
      width: "500px",
      disableClose: true,
      data: service
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log("result: ", result)
      if (result) this.reload(this.currentPage);
    });
  }

  confirmDelete(id: any) {
    this.deletedId = id;
  }

  onDelete() {
    this.merchantOptionService.deleteMerchantOption(this.deletedId).subscribe(
      res => {
        this.reload(this.currentPage);
      },
      err => {
        console.log(err);
      }
    );
  }

  activate(id: any) {
    this.merchantOptionService.activate(id).subscribe(
      (res: any) => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
      }
    );
  }

  deactivate(id: any) {
    this.merchantOptionService.deactivate(id).subscribe(
      (res: any) => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
      }
    );
  }

  /** Function for pagination */
  onOptionCountChange() {
    // console.log("event");
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }

}
