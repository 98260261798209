import { Injectable } from '@angular/core';
// import { BASE_END_POINT, TOKEN_EXPIRED, INVALID_TOKEN } from '../app.server';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Subject, Observable } from '../../../node_modules/rxjs';
import { Router } from '@angular/router';
import { Category } from './category';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root'})
export class AppCategoriesService {
  private categories: any[];
  BASE_END_POINT = environment.url;
  private categoryUpdated = new Subject<any[]>();

  constructor(private http: HttpClient, private router: Router) {}

  // getCategories(): void {
  //   let series = 0;
  //   this.http.get(BASE_END_POINT + 'categories?token=' + localStorage.getItem('token'))
  //     .pipe(
  //       map((categoriesData: any[]) => {
  //         return categoriesData.map((category: any[]) => {
  //           series++;
  //           return {
  //             ...category,
  //             series: series
  //           };
  //         });
  //       })
  //     )
  //     .subscribe(transformCategories => {
  //       this.categories = transformCategories;
  //       this.categoryUpdated.next([...this.categories]);
  //     },
  //   (error) => {
  //     console.log(error);
  //     if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
  //       localStorage.clear();
  //       this.router.navigateByUrl('/login');
  //     }
  //   }
  //   );
  // }

  getCategories(): Observable<Category[]>{
    return this.http.get<Category[]>(this.BASE_END_POINT+'categories?token='+localStorage.getItem('token'));
  }

  categoryUpdateListener() {
    return this.categoryUpdated.asObservable();
  }
  addCategory(title: string, description: string, icon: string) {
    return this.http.post( this.BASE_END_POINT + 'categories?token=' + localStorage.getItem('token'), { title: title, description: description, icon: icon});
  }
  editCategory(id: string) {
    return this.http.get( this.BASE_END_POINT + 'categories/' + id + '?token=' + localStorage.getItem('token'));
  }
  updateCategory(id: string, prepareData: Object) {
    return this.http.patch(this.BASE_END_POINT + 'categories/' + id + '?token=' + localStorage.getItem('token'), prepareData);
  }
  deleteCategory(id: string) {
    return this.http.delete( this.BASE_END_POINT + 'categories/' + id + '?token=' + localStorage.getItem('token'));
  }
}
