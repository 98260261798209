import { Component, ViewChild } from "@angular/core";
import { NgForm } from "../../../../node_modules/@angular/forms";
import { Router } from "../../../../node_modules/@angular/router";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../../app.server";
import { AppAuthService } from "../../auth/auth.service";
import { AppCurrencyService } from "../currency.service";

@Component({
  selector: 'app-currency-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class AppCurrencyNewComponent {
  @ViewChild('fCurrency') currencyData: NgForm;
  title = '';
  to_riel = '';
  flag = '';



  constructor( private currencySevice: AppCurrencyService, private router: Router, private authService: AppAuthService) {}

  selectedImage: File = null;

  imgSrc: any;
  


  getFile(event){
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => { this.imgSrc = reader.result; }


      reader.readAsDataURL(file);
    }
  }

  onSubmit() {

    this.title = this.currencyData.value.title;
    this.to_riel = this.currencyData.value.to_riel;

    const formData = new FormData();
    formData.append('name', this.title);
    formData.append('to_riel', this.to_riel);
    formData.append('flag',this.selectedImage);


    this.currencySevice.addCurrency(formData)
    .subscribe(() => {
      this.router.navigateByUrl('/currencies');
    },
    (error) => {
      console.log(error);
      if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
        this.authService.needLogin();
      }
    });
  }
  onGoBack() {
    this.router.navigateByUrl('/currencies');
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46) {
      if (event.target.value != '') {
        if (event.target.value.includes('.')) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
  }
}
