import { Component, OnInit, ViewChild } from "@angular/core";
import { AppCategoriesService } from "../categories.service";
import { AppAuthService } from "../../auth/auth.service";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../../app.server";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";

@Component({
  selector: "app-categories-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"]
})
export class AppCategoriesListComponent implements OnInit {
  categories: any[] = [];
  isLoading = true;
  private categoryId = null;
  dtOptions: any = {};
  displayedColumns = ["index", "title", "description", "icon", "action"];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private categoryService: AppCategoriesService,
    private authService: AppAuthService
  ) {}
  ngOnInit() {
    // this.dtOptions = {
    //   searching: false,
    //   lengthChange: false
    // };
    // this.categoryService.getCategories();
    // this.categoryService.categoryUpdateListener()
    //   .subscribe((displayCategories: any[]) => {
    //     console.log(displayCategories);
    //     this.categories = [];
    //     this.categories = displayCategories;
    //     this.isLoading = false;
    //   },
    //     (error) => {
    //       this.isLoading = false;
    //       console.log(error);
    //     });
    this.categoryService.getCategories().subscribe(
      data => {
        this.isLoading = false;
        this.dataSource.data = data;
        console.log(this.dataSource.data);
      },
      error => {
        this.isLoading = false;
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  onDeleteCategory(id) {
    this.categoryId = id;
  }
  onReallyDeleteCategory() {
    this.categoryService.deleteCategory(this.categoryId).subscribe(
      () => {
        this.reload();
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  reload() {
    this.dataSource.data = [];
    this.isLoading = true;
    this.categoryService.getCategories().subscribe(
      data => {
        this.isLoading = false;
        this.dataSource.data = data;
      },
      error => {
        this.isLoading = false;
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
