import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchant-payment-history',
  templateUrl: './merchant-payment-history.component.html',
  styleUrls: ['./merchant-payment-history.component.css']
})
export class MerchantPaymentHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
