import { Component, OnInit, ViewChild } from '@angular/core';
import { PosService } from '../pos.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TOKEN_EXPIRED, INVALID_TOKEN } from 'src/app/app.server';
import { AppAuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-pos-new',
  templateUrl: './pos-new.component.html',
  styleUrls: ['./pos-new.component.css']
})
export class PosNewComponent implements OnInit {

  @ViewChild('posForm') posForm: NgForm;

  constructor(private _posService: PosService, private _router: Router, private _route: ActivatedRoute, private authService: AppAuthService) { }

  username = '';
  name = '';
  note = '';
  password = '';


  ngOnInit() {
  }


  onSubmit(){
    const pos = {
      username: this.posForm.value.username,
      name: this.posForm.value.name,
      note: this.posForm.value.note,
      password: this.posForm.value.password
    }
    this._posService.addPos(pos).subscribe(
      ()=> { this._router.navigateByUrl('/pos')},
      (error) => {
        if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
          this.authService.needLogin();
        }
      }
    )
  }

  onGoBack(){
    this._router.navigate(['../'], { relativeTo: this._route });
  }

}
