import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sale-shift',
  templateUrl: './sale-shift.component.html',
  styleUrls: ['./sale-shift.component.css']
})
export class SaleShiftComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
