import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OptionService } from 'src/app/option/option.service';
import { MerchantService } from 'src/app/merchants/merchant.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MerchantOptionService } from '../merchant-option.service';

@Component({
  selector: 'app-create-merchant-option',
  templateUrl: './create-merchant-option.component.html',
  styleUrls: ['./create-merchant-option.component.css']
})
export class CreateMerchantOptionComponent implements OnInit {
  options: any;
  selectedOption: any;
  merchants: any;
  selectedMerchant: any;

  constructor(
    public dialogRef: MatDialogRef<CreateMerchantOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private optionService: OptionService,
    private merchantService: MerchantService,
    private merchantOptionService: MerchantOptionService
  ) { }

  merchantOptionFormGroup: FormGroup;
  quantityControl = new FormControl(20, [
    Validators.required,
    Validators.min(0)
  ]);
  merchantControl = new FormControl("", [Validators.required]);
  optionControl = new FormControl("", [Validators.required]);
  ngOnInit() {
    this.merchantOptionFormGroup = new FormGroup({
      optionControl: this.optionControl,
      merchantControl: this.merchantControl,
      quantityControl: this.quantityControl
    })
    this.getOption();
    this.getMerchant();
  }

  onOptionChange(event) {
    console.log("option: ", event)
    this.selectedOption = event;
  }

  onMerchantChange(event) {
    this.selectedMerchant = event;
  }

  getOption() {
    this.options = [];
    this.optionService.getAllOptions().subscribe(
      (res: any) => {
        this.options = res;
        this.selectedOption = this.options[0];
      },
      err => {
        console.log(err);
      }
    );
  }

  getMerchant() {
    this.merchants = [];
    this.merchantService.getMerchants().subscribe(
      (res: any) => {
        this.merchants = res;
        this.selectedMerchant = this.merchants[0];
      },
      err => {
        console.log(err);
      }
    );
  }

  createMerchantOption() {
    const data = {
      option: this.selectedOption._id,
      merchant: this.selectedMerchant._id,
      quantity: this.quantityControl.value
    };
    this.merchantOptionService.createMerchantOption(data).subscribe(
      res => {
        this.dialogRef.close(1);
      },
      err => {
        this.dialogRef.close(0);
      }
    );
  }

  cancel() {
    this.dialogRef.close(0);
  }
}
