import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { OptionService } from "../option.service";
import { DestinationServiceService } from "src/app/destination-service/destination-service.service";
import { AppTicketService } from "src/app/services/services.service";
import { MerchantService } from "src/app/merchants/merchant.service";
import { ServiceTypeService } from "src/app/service-type/service-type.service";

@Component({
  selector: "app-create-option-dialog",
  templateUrl: "./create-option-dialog.component.html",
  styleUrls: ["./create-option-dialog.component.css"]
})
export class CreateOptionDialogComponent implements OnInit {
  types: any;
  selectedType: any;
  destinationServices: any;
  selectedDestinationService: any;
  selectedService: any;
  merchants: any;
  selectedMerchant: any;
  constructor(
    public dialogRef: MatDialogRef<CreateOptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private optionService: OptionService,
    private serviceTypeService: ServiceTypeService,
    private destinationServiceService: DestinationServiceService,
    private merchantService: MerchantService
  ) { }

  optionFormGroup: FormGroup;
  nameControl = new FormControl("", [Validators.required]);
  // quantityControl = new FormControl(20, [
  //   Validators.required,
  //   Validators.min(0)
  // ]);
  descriptionControl = new FormControl("");
  originalPriceControl = new FormControl(0, [
    Validators.required,
    Validators.min(0)
  ]);
  touristPriceControl = new FormControl(0, [
    Validators.required,
    Validators.min(0)
  ]);
  guidePriceControl = new FormControl(0, [
    Validators.required,
    Validators.min(0)
  ]);
  commissionControl = new FormControl(0, [Validators.required]);
  destinationServiceControl = new FormControl("", [Validators.required]);
  // merchantControl = new FormControl("", [Validators.required]);
  // typeControl = new FormControl("", [Validators.required]);
  ngOnInit() {
    this.optionFormGroup = new FormGroup({
      nameControl: this.nameControl,
      // quantityControl: this.quantityControl,
      descriptionControl: this.descriptionControl,
      originalPriceControl: this.originalPriceControl,
      touristPriceControl: this.originalPriceControl,
      guidePriceControl: this.guidePriceControl,
      commissionControl: this.commissionControl,
      destinationServiceControl: this.destinationServiceControl,
      // merchantControl: this.merchantControl,
      // typeControl: this.typeControl
    });

    // this.getServiceType();
    this.getDestinationServices();
    this.onFormGroupChange();
  }

  onFormGroupChange() {
    this.nameControl.valueChanges.subscribe(val => {
      this.descriptionControl.setValue(this.selectedDestinationService.description + " with " + this.nameControl.value)
    });
    this.originalPriceControl.valueChanges.subscribe(val => {
      this.touristPriceControl.setValue(Number(this.originalPriceControl.value) + Number(this.commissionControl.value));
      this.guidePriceControl.setValue(Number(this.originalPriceControl.value) + Number(this.commissionControl.value));
    });
    this.commissionControl.valueChanges.subscribe(val => {
      this.touristPriceControl.setValue(Number(this.originalPriceControl.value) + Number(this.commissionControl.value));
      this.guidePriceControl.setValue(Number(this.originalPriceControl.value) + Number(this.commissionControl.value));
    });
  }

  createOption() {
    const data = {
      name: this.nameControl.value,
      // quantity: this.quantityControl.value,
      description: this.descriptionControl.value,
      original_price: this.originalPriceControl.value,
      tourist_price: this.touristPriceControl.value,
      guide_price: this.guidePriceControl.value,
      commission: this.commissionControl.value,
      service_destination: this.selectedDestinationService._id,
      // merchant: this.selectedMerchant._id
    };
    this.optionService.createOption(data).subscribe(
      res => {
        this.dialogRef.close(1);
      },
      err => {
        this.dialogRef.close(0);
      }
    );
  }

  onDestinationServiceChange(event) {
    this.descriptionControl.setValue(this.selectedDestinationService.description + " with " + this.nameControl.value)
  }

  cancel() {
    this.dialogRef.close(0);
  }

  getServiceType() {
    this.types = [];
    this.serviceTypeService.getServiceTypes().subscribe(
      (res: any) => {
        this.types = res;
        this.selectedType = this.types[0];
        this.getDestinationServices();
        this.getMerchants(this.selectedType._id);
      },
      err => {
        console.log(err);
      }
    );
  }

  onTypeChange(event) {
    this.selectedType = event;
    this.getDestinationServices();
    this.getMerchants(this.selectedType._id);
  }

  getDestinationServices() {
    this.destinationServices = [];
    this.destinationServiceService.getDestinationServicesWithoutPagination().subscribe(
      (res: any) => {
        this.destinationServices = res;
        this.selectedDestinationService = this.destinationServices[0];
      },
      err => {
        console.log(err);
      }
    );
  }

  getMerchants(serviceType: any) {
    this.merchants = [];
    this.merchantService.getMerchantByType(serviceType).subscribe(
      (res: any) => {
        this.merchants = res;
        this.selectedMerchant = this.merchants[0];
      },
      err => {
        console.log(err);
      }
    );
  }
}
