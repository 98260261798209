import {
  Component,
  OnInit,
  ViewChild
} from "@angular/core";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../../app.server";
import { AppAuthService } from "../../auth/auth.service";
import { AppTicketService } from "../services.service";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { NotificationService } from "src/shared/notification.service";
import { ServiceTypeService } from "src/app/service-type/service-type.service";

@Component({
  selector: "app-tickets-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"]
})
export class AppTicketsListComponent implements OnInit {
  tickets: any[] = [];
  isLoading = true;
  dtOptions: any = {};
  private ticketId = null;
  displayedColumns = [
    "index",
    "code",
    "title",
    // "total",
    "service_type",
    "service_detail",
    "status_code",
    "action"
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  serviceTypes = [];
  selectedType = "";
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];

  constructor(
    private ticketsService: AppTicketService,
    private authService: AppAuthService,
    private notificationService: NotificationService,
    private serviceTypeService: ServiceTypeService
  ) { }

  ngOnInit() {
    this.reload(this.currentPage);
    this.getServiceTypes();
  }
  onDeleteTicket(id) {
    this.ticketId = id;
  }
  onReallyDeleteTicket() {
    this.ticketsService.deleteTicket(this.ticketId).subscribe(
      () => {
        // console.log(response);
        // this.ticketsService.getTickets();
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  reload(page: any) {
    if (page) {
      this.currentPage = page;
    }
    this.dataSource.data = [];
    this.isLoading = true;
    this.ticketsService.getServices(this.selectedType, this.currentPage, this.count).subscribe(
      data => {
        console.log("service data: ", data);
        this.isLoading = false;
        this.dataSource.data = data;
      },
      error => {
        this.isLoading = false;
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      // console.log(property);
      switch (property) {
        case "merchant": {
          return item["merchant_id"].user_id.name;
        }
        case "category": {
          return item["category"].title;
        }
        default: {
          return item[property];
        }
      }
    };
    this.dataSource.sort = this.sort;
  }

  activate(id: string) {
    this.ticketsService.activate(id).subscribe(
      res => {
        this.notificationService.showSuccess("Service activated successfully.");
        this.reload(this.currentPage);
      },
      err => {
        this.notificationService.showError("Service activation failed!");
      }
    );
  }

  deactivate(id: string) {
    this.ticketsService.deactivate(id).subscribe(
      res => {
        this.notificationService.showSuccess(
          "Service deactivated successfully."
        );
        this.reload(this.currentPage);
      },
      err => {
        this.notificationService.showError("Service deactivation failed!");
      }
    );
  }

  getServiceTypes() {
    this.serviceTypes = [];
    this.serviceTypeService.getServiceTypes().subscribe(
      (res: any) => {
        this.serviceTypes = res;
      },
      error => {
        console.log(error);
      }
    );
  }

  onTypeChange() {
    this.currentPage = 1;
    this.reload(this.currentPage);
  }
  /** Function for pagination */
  onOptionCountChange() {
    // console.log("event");
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
