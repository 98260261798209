import { Component } from "@angular/core";

@Component({
  selector: 'app-tickets',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class AppServicesComponent {

}
