import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../user.service';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TOKEN_EXPIRED, INVALID_TOKEN } from 'src/app/app.server';
import { AppAuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.css']
})
export class UserNewComponent implements OnInit {

  @ViewChild('userForm') userForm: NgForm;

  constructor(private _userService: UserService, private authService: AppAuthService, private _router: Router, private _route: ActivatedRoute) { }

  username = "";
  password = "";
  name = "";
  roles = [];
  defaultRole = '5b90a37755cee9325fcebd25';

  ngOnInit() {
    this._userService.getRoles().subscribe(
      (roles: any[]) => {
        roles.forEach(
          (value, index)=>{
            if (value['name']==='Merchant'){
              roles.splice(index,1);
            }
          }
        );
        roles.forEach(
          (value, index)=>{
            if (value['name']==='POS'){
              roles.splice(index,1);
            }
          }
        );
        this.roles = roles;
      },
      (error) => {
        if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
          this.authService.needLogin();
        }
      }
    );
  }

  onSubmit(){
    const user = {
      name: this.userForm.value.name,
      username: this.userForm.value.username,
      password: this.userForm.value.password,
      role: this.userForm.value.role
    }

    this._userService.addUser(user).subscribe(
      ()=>{this._router.navigateByUrl('users')},
      (error)=>{
        console.log(error);
        if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
          this.authService.needLogin();
        }
      }
    )
  }

  onGoBack() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

}
