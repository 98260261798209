import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MerchantOptionService {
  BASE_END_POINT = environment.url;
  constructor(private http: HttpClient) { }

  getMerchantOptions() {
    return this.http.get(
      this.BASE_END_POINT +
      "merchant_options?token=" +
      localStorage.getItem("token")
    );
  }

  getMerchantOptionsPagination(page: number, count: number) {
    let query = "&page=" + page + "&count=" + count;
    return this.http.get(
      this.BASE_END_POINT +
      "merchant_options?token=" +
      localStorage.getItem("token") + query
    );
  }

  createMerchantOption(data: any) {
    return this.http.post(
      this.BASE_END_POINT +
      "merchant_options?token=" +
      localStorage.getItem("token"),
      data
    );
  }

  updateMerchantOption(id: any, data: any) {
    return this.http.patch(
      this.BASE_END_POINT +
      "merchant_options/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      data
    );
  }

  deleteMerchantOption(id: any) {
    return this.http.delete(
      this.BASE_END_POINT +
      "merchant_options/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }

  activate(id: any) {
    const body = {
      is_active: true
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "merchant_options/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }

  deactivate(id: any) {
    const body = {
      is_active: false
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "merchant_options/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }
}
