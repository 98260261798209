import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { PosService } from "../pos.service";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";
import { AppAuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-pos-list",
  templateUrl: "./pos-list.component.html",
  styleUrls: ["./pos-list.component.css"]
})
export class PosListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ["index", "user.username", "user.name", "note", "action"];
  dataSource = new MatTableDataSource();

  deleteId = "";
  isLoading = true;
  currentPage = 1;
  count = 10;
  optionItemCount = [10, 20, 40, 100];

  constructor(
    private _posService: PosService,
    private authService: AppAuthService
  ) { }

  ngOnInit() {
    this.reload(this.currentPage);
    this.dataSource.sort = this.sort;
  }

  confirmDelete(id: string) {
    this.deleteId = id;
  }

  onDelete() {
    this._posService.deletePos(this.deleteId).subscribe(
      () => {
        this.reload(this.currentPage);
      },
      error => {
        console.log(error);
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }
  reload(page: any) {
    if (page) {
      this.currentPage = page;
    }
    this.dataSource.data = [];
    this.isLoading = true;
    this._posService.getPosPagination(this.currentPage, this.count).subscribe(
      data => {
        this.isLoading = false;
        this.dataSource.data = data;
      },
      error => {
        this.isLoading = false;
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }
  /** Function for pagination */
  onOptionCountChange() {
    // console.log("event");
    this.currentPage = 1;
    this.reload(this.currentPage);
  }

  onPreviousClick() {
    // console.log("prev called", this.count);
    if (this.currentPage > 1) {
      this.currentPage -= 1;
      this.reload(this.currentPage);
    }
  }

  noNext(data: any) {
    // console.log("no next", data);
    if (this.count == 0 || data.length < this.count) {
      return true;
    } else {
      return false;
    }
  }

  onNextClick() {
    // this.count = this.countFormGroup.get("countControl").value;
    this.currentPage += 1;
    this.reload(this.currentPage);
  }
}
