import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { LanguageService } from "../language.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-create-language",
  templateUrl: "./create-language.component.html",
  styleUrls: ["./create-language.component.css"]
})
export class CreateLanguageComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateLanguageComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private languageService: LanguageService
  ) {}

  languageFormGroup: FormGroup;
  nameFormControl = new FormControl("", [Validators.required]);
  descriptionControl = new FormControl("", [Validators.required]);

  ngOnInit() {
    this.languageFormGroup = new FormGroup({
      nameControl: this.nameFormControl,
      descriptionControl: this.descriptionControl
    });
  }

  createLanguage() {
    const data = {
      name: this.languageFormGroup.get("nameControl").value,
      description: this.languageFormGroup.get("descriptionControl").value
    };
    this.languageService.createLanguage(data).subscribe(
      res => {
        this.dialogRef.close("ok");
      },
      error => {
        console.log(error);
        this.dialogRef.close("cancel");
      }
    );
  }

  cancel() {
    this.dialogRef.close("cancel");
  }
}
