import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MerchantService } from "../merchant.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "src/app/app.server";
import { AppAuthService } from "src/app/auth/auth.service";
import { ServiceTypeService } from "src/app/service-type/service-type.service";
import { LanguageService } from "src/app/language/language.service";

@Component({
  selector: "app-merchant-new",
  templateUrl: "./merchant-new.component.html",
  styleUrls: ["./merchant-new.component.css"]
})
export class MerchantNewComponent implements OnInit {
  @ViewChild("merchantForm") merchantForm: NgForm;

  types = ["commission_base", "guarantee_base"];
  type: any;
  username = "";
  name = "";
  phone_number = "";
  email = "";
  website = "";
  address = "";
  password = "";
  logo: any;
  defaultServiceType = "";
  serviceTypes: any;
  // languages: any;
  defaultLanguage: "";

  constructor(
    private _merchantService: MerchantService,
    private _router: Router,
    private authService: AppAuthService,
    private _route: ActivatedRoute,
    private serviceTypeService: ServiceTypeService,
    // private languageService: LanguageService
  ) { }

  ngOnInit() {
    // this.checkPermission();
    this.permissionArray[0] = "Admin";
    this.type = this.types[0];
    this.getServiceTypes();
    // this.getLanguages();
  }

  selectedImage: File;
  nothingSelected = true;
  permissionArray = [];

  onSubmit() {
    console.log(this.merchantForm.value.type);
    // this.getCheckedPermissions();
    const formData = new FormData();
    formData.append("address", this.merchantForm.value.address);
    formData.append("logo", this.selectedImage);
    formData.append("email", this.merchantForm.value.email);
    formData.append("username", this.merchantForm.value.username);
    formData.append("name", this.merchantForm.value.name);
    formData.append("type", this.merchantForm.value.type);
    formData.append("service_type_id", this.merchantForm.value.service_type);
    // formData.append("language", this.merchantForm.value.language);
    formData.append("website", this.merchantForm.value.website);
    for (var i = 0; i < this.permissionArray.length; i++) {
      formData.append("permissions", this.permissionArray[i]);
    }
    formData.append("phone_number", this.merchantForm.value.phone_number);
    formData.append("password", this.merchantForm.value.password);

    this._merchantService.addMerchant(formData).subscribe(
      () => {
        this._router.navigateByUrl("/merchants");
      },
      error => {
        if (
          error.error.message === TOKEN_EXPIRED ||
          error.error.message === INVALID_TOKEN
        ) {
          this.authService.needLogin();
        }
      }
    );
  }

  imgSrc: any;

  getFile(event) {
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => {
        this.imgSrc = reader.result;
      };

      reader.readAsDataURL(file);
    }
  }

  onGoBack() {
    this._router.navigate(["../"], { relativeTo: this._route });
  }

  validateCheckbox() {
    let checkBoxes = document.getElementsByClassName("permission");
    for (var i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes.item(i)["checked"]) {
        this.nothingSelected = false;
        return;
      } else {
        this.nothingSelected = true;
      }
    }
  }

  checkPermission() {
    let elements = document.getElementsByClassName("permission");
    for (var i = 0; i < elements.length; i++) {
      if (elements.item(i)["value"] === "Admin") {
        elements.item(i)["checked"] = true;
      }
    }
    this.validateCheckbox();
  }

  getCheckedPermissions() {
    let checkBoxes = document.getElementsByClassName("permission");
    this.permissionArray = [];
    for (var i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes.item(i)["checked"]) {
        this.permissionArray.push(checkBoxes.item(i)["value"]);
      }
    }
  }

  getServiceTypes() {
    this.serviceTypeService.getServiceTypes().subscribe(
      res => {
        this.serviceTypes = res;
        this.defaultServiceType = this.serviceTypes[0]._id;
        console.log(res);
      },
      error => {
        console.log(error);
      }
    );
  }

  // getLanguages() {
  //   this.languageService.getLanguages().subscribe(
  //     res => {
  //       this.languages = res;
  //       this.defaultLanguage = this.languages[0]._id;
  //       console.log("language: ", res);
  //     },
  //     error => {
  //       console.log(error);
  //     }
  //   );
  // }
}
