import { Component, ViewChild, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import {
	Router,
	ActivatedRoute
} from "@angular/router";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../../app.server";
import { AppAuthService } from "../../auth/auth.service";
import { AppTicketService } from "../services.service";
import { MerchantService } from "src/app/merchants/merchant.service";

@Component({
	selector: "app-ticket-edit",
	templateUrl: "./edit.component.html",
	styleUrls: ["./edit.component.css"]
})
export class AppTicketEditComponent implements OnInit {
	serviceTypes: any[] = [];
	serviceDetails: any[] = [];
	@ViewChild("fTicket") ticketData: NgForm;
	private ticketId = "";
	private feature_image: File;
	title = "";
	price = "";
	original_price = "";
	guide_price = "";
	tourist_price = "";
	defaultServiceType = "";
	defaultServiceDetail = "";
	total = 0;
	commission = "";
	link = "http://www.";
	previewFile = "";
	detail = "";
	imageArray: any;
	inputFile: any;
	deleted_list = [];

	merchants: any[];

	constructor(
		private _merchantService: MerchantService,
		private ticketService: AppTicketService,
		private authService: AppAuthService,
		private router: Router,
		private route: ActivatedRoute
	) { }

	ngOnInit() {
		this._merchantService.getMerchants().subscribe(
			data => {
				this.merchants = data;
			},
			error => {
				if (
					error.error.message === TOKEN_EXPIRED ||
					error.error.message === INVALID_TOKEN
				) {
					this.authService.needLogin();
				}
			}
		);
		this.ticketService.getServiceTypes().subscribe(
			(serviceTypes: any[]) => {
				this.serviceTypes = serviceTypes;
			},
			error => {
				if (
					error.error.message === TOKEN_EXPIRED ||
					error.error.message === INVALID_TOKEN
				) {
					this.authService.needLogin();
				}
			}
		);
		this.ticketService.getServiceDetails().subscribe(
			(serviceDetails: any[]) => {
				this.serviceDetails = serviceDetails;
			},
			error => {
				if (
					error.error.message === TOKEN_EXPIRED ||
					error.error.message === INVALID_TOKEN
				) {
					this.authService.needLogin();
				}
			}
		);
		this.ticketService
			.getTicketService(this.route.snapshot.params["id"])
			.subscribe(
				ticket => {
					console.log("ticket for edit:", ticket);
					this.ticketId = ticket["_id"];
					this.title = ticket["title"];
					this.price = ticket["price"];
					this.original_price = ticket["original_price"];
					this.guide_price = ticket["guide_price"];
					this.tourist_price = ticket["tourist_price"];
					this.defaultServiceType = ticket["service_type"] ? ticket["service_type"]["_id"] : "";
					this.defaultServiceDetail = ticket["service_detail"]["_id"];
					this.total = ticket["total"];
					this.commission = ticket["commission"];
					this.link = ticket["link"];
					this.readImages(ticket["feature_image"]);
					this.detail = ticket["detail"];
				},
				error => {
					if (
						error.error.message === TOKEN_EXPIRED ||
						error.error.message === INVALID_TOKEN
					) {
						this.authService.needLogin();
					}
				}
			);
	}
	onFileChanged(event) {
		// const context = this;
		// var files = event.target.files;
		// for (var i = 0; i < files.length; i++) {
		//     var file = files[i];
		//     var picReader = new FileReader();
		//     picReader.readAsDataURL(file);
		//     picReader.onload = function() {
		//       context.imageArray.push({img: picReader.result, name: file.name, file: file});
		//     };
		//     picReader.onerror = function(error) {
		//       console.log("Error: ", error);
		//     };
		// }
		this.feature_image = event.target.files;
		var files = event.target.files;
		var output = document.getElementById("imagePreview");
		for (let i = 0; i < files.length; i++) {
			// var file = files[i];
			// var reader = new FileReader();
			// reader.onload = (event: ProgressEvent) => {
			//   this.srcArray.push((<FileReader>event.target).result);
			// };
			// reader.readAsDataURL(file);
			// var picReader = new FileReader();
			// picReader.addEventListener("load", function (event) {
			//   var picFile = event.target;
			//   var div = document.createElement("div");
			//   div.style.marginRight = '5px';
			//   div.innerHTML = "<img src='" + picFile['result'] + "'" + "title='" + file.name + "' width='100px' height='100px'/>";
			//   output.insertBefore(div, null);
			// });
			// picReader.readAsDataURL(file);

			let file = files[i];
			let reader = new FileReader();
			let context = this;
			reader.readAsDataURL(file);
			reader.onload = function () {
				context.imageArray.push({
					img: reader.result,
					name: file.name,
					file: file,
					file_index: i,
					old: false
				});
			};
			reader.onerror = function (error) {
				console.log("Error: ", error);
			};
		}
		console.log('onFileChange imageArray:', this.imageArray);
	}
	private readImages(images: any[]) {
		// var output = document.getElementById("imagePreview");
		// for (var i = 0; i < images.length; i++) {
		//   var div = document.createElement("div");
		//       div.style.marginRight = '5px';
		//       div.innerHTML = "<img src='" + images[i] + "'" + "width='100px' height='100px'/>";
		//       output.insertBefore(div, null);
		// }
		this.imageArray = [];
		for (let i = 0; i < images.length; i++) {
			const name = images[i].split("/").pop();
			this.imageArray.push({
				img: images[i],
				name: name,
				file: name,
				file_index: name,
				old: true
			});
		}
		console.log('onReadImage imageArray:', this.imageArray);
	}

	deleteImage(item: any) {
		console.log('onDeleteImage imageArray:', this.imageArray);
		const index = this.imageArray.indexOf(item);
		if (index > -1) {
			if (this.imageArray[index].old) {
				this.deleted_list.push(this.imageArray[index]);
			}
			this.imageArray.splice(index, 1);
		}
		if (this.imageArray.length <= 0) {
			this.inputFile = null;
		}

		console.log('onDeleteImage deleted_list:', this.deleted_list);
	}
	onSubmit() {
		console.log('onSubmit imageArray:', this.imageArray);

		this.ticketService
			.updateTicket(
				this.ticketData,
				this.ticketId,
				this.imageArray,
				this.deleted_list,
				this.feature_image
			)
			.subscribe(
				() => {
					this.router.navigateByUrl("/services");
				},
				error => {
					console.log(error);
					if (
						error.error.message === TOKEN_EXPIRED ||
						error.error.message === INVALID_TOKEN
					) {
						this.authService.needLogin();
					}
				}
			);
	}
	onGoBack() {
		this.router.navigateByUrl("/services");
	}
	isDigit(event) {
		const inputKeyCode = event.keyCode ? event.keyCode : event.which;
		if (inputKeyCode != null) {
			if (inputKeyCode == 45) {
				event.preventDefault();
			}
		}
	}
}
