import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class MerchantServiceService {
  BASE_END_POINT = environment.url;
  constructor(private http: HttpClient) { }

  getAllMerchantServices(
    type: any,
    merchant: any,
    page: number,
    count: number
  ) {
    let query =
      "?token=" +
      localStorage.getItem("token") +
      "&count=" +
      count +
      "&page=" +
      page;
    if (type !== "") {
      query = query + "&service_type_id=" + type;
    }

    if (merchant !== "") {
      query = query + "&merchant_id=" + merchant;
    }
    return this.http.get(this.BASE_END_POINT + "merchant_services" + query);
  }

  getMerchants() {
    return this.http.get(
      this.BASE_END_POINT +
      "merchants?parent=null&token=" +
      localStorage.getItem("token")
    );
  }

  getMerchantsByServiceType(serviceType: any = null) {
    let query = "?parent=null&token=" + localStorage.getItem("token");
    if (serviceType !== null) {
      query = query + "&service_type_id=" + serviceType;
    }
    return this.http.get(this.BASE_END_POINT + "merchants" + query);
  }

  getService() {
    return this.http.get(
      this.BASE_END_POINT +
      "services/admin?token=" +
      localStorage.getItem("token")
    );
  }

  getServicesByType(serviceType: any = null) {
    let query = "?token=" + localStorage.getItem("token");
    if (serviceType !== null) {
      query = query + "&service_type_id=" + serviceType;
    }
    return this.http.get(this.BASE_END_POINT + "services/admin" + query);
  }

  createMerchantService(data: any) {
    return this.http.post(
      this.BASE_END_POINT +
      "merchant_services?token=" +
      localStorage.getItem("token"),
      data
    );
  }

  updateMerchantService(id: any, data: any) {
    return this.http.patch(
      this.BASE_END_POINT +
      "merchant_services/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      data
    );
  }

  deleteMerchantService(id: any) {
    return this.http.delete(
      this.BASE_END_POINT +
      "merchant_services/" +
      id +
      "?token=" +
      localStorage.getItem("token")
    );
  }

  activate(id: any) {
    const body = {
      is_active: true
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "merchant_services/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }

  deactivate(id: any) {
    const body = {
      is_active: false
    };
    return this.http.patch(
      this.BASE_END_POINT +
      "merchant_services/" +
      id +
      "?token=" +
      localStorage.getItem("token"),
      body
    );
  }
}
