import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DestinationService } from "src/app/destination/destination.service";
import { AppTicketService } from "src/app/services/services.service";
import { DestinationServiceService } from "../destination-service.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-edit-destination-service-dialog",
  templateUrl: "./edit-destination-service-dialog.component.html",
  styleUrls: ["./edit-destination-service-dialog.component.css"]
})
export class EditDestinationServiceDialogComponent implements OnInit {
  destinations: any;
  selectedDestination: any;
  services: any;
  selectedService: any;
  constructor(
    public dialogRef: MatDialogRef<EditDestinationServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private destinationService: DestinationService,
    private ticketService: AppTicketService,
    private destinationServiceService: DestinationServiceService
  ) { }

  editDestinationServiceFormGroup: FormGroup;
  destinationControl = new FormControl("", [Validators.required]);
  serviceControl = new FormControl("", [Validators.required]);
  descriptionControl = new FormControl(this.object.description);

  ngOnInit() {
    console.log(this.object);

    this.editDestinationServiceFormGroup = new FormGroup({
      destinationControl: this.destinationControl,
      serviceControl: this.serviceControl,
      descriptionControl: this.descriptionControl
    });
    this.getDestinations();
    this.getServices();
  }

  onCancel() {
    this.dialogRef.close();
  }

  getDestinations() {
    this.destinations = [];
    this.destinationService.getDestinations().subscribe(
      (res: any) => {
        this.destinations = res;
        this.selectedDestination = this.object.destination;
      },
      err => {
        console.log(err);
      }
    );
  }

  getServices() {
    this.services = [];
    this.ticketService.getAllServices().subscribe(
      (res: any) => {
        this.services = res;
        this.selectedService = this.object.service;
      },
      err => {
        console.log(err);
      }
    );
  }

  onDestinationChange(event) {
    this.descriptionControl.setValue(this.selectedService.title + " to " + this.selectedDestination.name);
  }

  onServiceChange(event) {
    this.descriptionControl.setValue(this.selectedService.title + " to " + this.selectedDestination.name);
  }

  onSubmit() {
    const id = this.object._id;
    const data = {
      destination: this.selectedDestination._id,
      service: this.selectedService._id,
      description: this.descriptionControl.value
    };
    this.destinationServiceService.updateDestinationService(id, data).subscribe(
      (res: any) => {
        this.dialogRef.close(1);
      },
      err => {
        this.dialogRef.close();
      }
    );
  }
}
