import { Component, ViewChild, OnInit } from "@angular/core";
import { NgForm } from "../../../../node_modules/@angular/forms";
import { Router, ActivatedRoute } from "../../../../node_modules/@angular/router";
import { TOKEN_EXPIRED, INVALID_TOKEN } from "../../app.server";
import { AppAuthService } from "../../auth/auth.service";
import { AppCurrencyService } from "../currency.service";

@Component({
  selector: 'app-categories-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class AppCurrencyEditComponent implements OnInit {
  @ViewChild('fCurrency') fCurrency: NgForm;
  title = '';
  to_riel = '';
  imgSrc: any;
  isLoading = false;
  private currencyId = null;
  selectedImage: File;

  constructor( private currencySevice: AppCurrencyService, private router: Router, private authService: AppAuthService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.isLoading = true;
    this.currencyId = this.route.snapshot.params['id'];
    this.currencySevice.editCurrency(this.currencyId)
    .subscribe(currency => {
      this.currencyId = currency['_id'];
      this.title = currency['name'];
      this.to_riel = currency['to_riel'];
      this.imgSrc = currency['flag'];
      this.isLoading = false;
    },
    (error) => {
      this.isLoading = false;
      console.log(error);
      if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
        this.authService.needLogin();
      } else if (error.type === 'error') {
        this.router.navigateByUrl('/currencies');
      }
    });
  }

  getFile(event){
    this.selectedImage = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => { this.imgSrc = reader.result; }


      reader.readAsDataURL(file);
    }
  }

  onSubmit() {
    this.isLoading = true;

    const formData = new FormData();
    formData.append('name', this.fCurrency.value.title);
    formData.append('to_riel', this.fCurrency.value.to_riel);

    if (this.selectedImage){
      formData.append('flag',this.selectedImage);
    }
    



    this.currencySevice.updateCurrency(this.currencyId , formData )
    .subscribe(() => {
      this.isLoading = false;
      console.log('category updated');
      this.router.navigateByUrl('/currencies');
    },
  (error) => {
    this.isLoading = false;
    console.log(error);
    if (error.error.message === TOKEN_EXPIRED || error.error.message === INVALID_TOKEN) {
      this.authService.needLogin();
    }
  });
  }
  onGoBack() {
    this.router.navigateByUrl('/currencies');
  }
}
