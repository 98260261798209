import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchant-option',
  templateUrl: './merchant-option.component.html',
  styleUrls: ['./merchant-option.component.css']
})
export class MerchantOptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
